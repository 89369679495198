<template>
    <div>
        <div class="title-menu"> Monthly Transaction</div>

        <div class="wrap-filter">
            <div class="btn-add" @click="showModalAdd()"> Tambah </div>
            
            <div class="btn-add ml-3" @click="showAddExcel()"> Import Data </div>

            <div class="float-right">
                <div class="t-pcg">Jenis Bank </div>
                <select class="slc-pcg" v-model="bank_code" @change="changeBank()">
                    <option value="" disabled> Pilih Bank </option>
                    <option :value="data.bank_code" v-for="(data,index) in data_bank" :key="index"> {{data.name}} </option>
                    
                </select>
            </div>
        </div>

        <div class="wrap-table">
            <table class="table table-striped table-hover table-borderless">
                <thead >
                    <tr style="height: 23px">
                        <th scope="col" class="text-center">No</th>
                        <th scope="col" class="text-left">Transaksi Rata-rata</th>
                        <th scope="col" class="text-center">Status</th>
                        <!-- <th scope="col" class="text-center">Trading</th> -->
                        <th class="text-center" colspan="2" style="width:30%">Aksi</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data_res,index) in data" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td scope="row" class="text-left">{{data_res.monthly_transaction}} </td>
                        <td scope="row" class="text-center">{{data_res.is_publish ? 'Aktif' : 'Tidak Aktif'}} </td>
                        <!-- <td scope="row" class="text-center">{{data_res.is_trading ? 'Tersedia' : 'Tidak Tersedia'}} </td> -->
                        
                        <td class="text-center"> 
                           <div class="text-center btn-detail" @click="showModalDetail(data_res)"> Lihat Detail  </div> 
                        </td>
                        
                        <td class="text-center">
                            <div class="text-center btn-edit" @click="showModalEdit(data_res)"> Ubah </div>
                        </td>
                    
                    </tr>

                </tbody>
            </table>

            <div class="flex-right mt-5">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    @click.native="changePage"
                ></b-pagination>
            </div>
        </div>

        <b-modal ref="my-modal-detail" hide-footer hide-header title="lihat detail">
            <div class="wrap-modal-view" v-if="data_modal">
                <div class="title-modal mb-5"> Detail Transaction</div>

                    <div class="row">
                        <div class="col-6">
                            <label for="name" class="label-modal"> Kode Bank</label>
                        </div>

                        <div class="col-6">
                            <label for="name" class="label-modal bold">{{data_modal.bank_code}}</label>
                        </div>
                    </div>

                     <div class="row">
                        <div class="col-6">
                            <label for="name" class="label-modal"> Status</label>
                        </div>

                        <div class="col-6">
                            <label for="name" class="label-modal bold">{{data_modal.is_publish ? 'Aktif' : 'Tidak Aktif'}}</label>
                        </div>
                    </div>

                     <div class="row">
                        <div class="col-6">
                            <label for="name" class="label-modal"> Kode Transaksi Rata-rata</label>
                        </div>

                        <div class="col-6">
                            <label for="name" class="label-modal bold">{{data_modal.monthly_transaction_code}}</label>
                        </div>
                    </div>

                     <div class="row">
                        <div class="col-6">
                            <label for="name" class="label-modal"> Transaksi Rata-rata (Bulan)</label>
                        </div>

                        <div class="col-6">
                            <label for="name" class="label-modal bold">{{data_modal.monthly_transaction}}</label>
                        </div>
                    </div>

                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideModalDetail"> Tutup</div>
                    </div>
            </div>
        </b-modal>

        <b-modal ref="my-modal-edit" hide-footer hide-header title="edit">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Ubah Transaction</div>
                
                <form @submit.prevent="edit">
                    <!-- <label for="trans" class="label-modal"> Transaksi Perbulan</label>
                    <input type="text" v-model="form_edit.monthly_transaction" id="trans" class="form-modal mb-3">

                    <label for="trans_en" class="label-modal"> Transaksi Perbulan (English)</label>
                    <input type="text" v-model="form_edit.monthly_transaction_en" id="trans_en" class="form-modal mb-3">

                    <div class="row mb-3">
                        <div class="col">
                            <label for="address" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form_edit.is_publish">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="true"> Aktif </option>
                                <option :value="false"> Tidak Aktif </option>
                            </select>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col">
                            <label for="trans" class="label-modal"> Kode Transaksi Perbulan</label>
                            <input type="text" v-model="form_edit.monthly_transaction_code" id="trans" class="form-modal mb-3">
                        </div>
                        <div class="col">
                            <label for="trans" class="label-modal"> Kode Bank</label>
                            <input type="text" v-model="form_edit.bank_code" id="trans" class="form-modal mb-3">
                        </div>
                    </div>

                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalEdit"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Ubah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-add" hide-footer hide-header title="add">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Tambah Transaction</div>
                
                <form enctype="multipart/form-data" @submit.prevent="add">
                    <label for="trans" class="label-modal"> Transaksi Perbulan</label>
                    <input type="text" v-model="form.monthly_transaction" id="trans" class="form-modal mb-3">

                    <label for="trans_en" class="label-modal"> Transaksi Perbulan (English)</label>
                    <input type="text" v-model="form.monthly_transaction_en" id="trans_en" class="form-modal mb-3">

                    <div class="row mb-3">
                        <div class="col">
                            <label for="trans_code" class="label-modal"> Kode Transaksi</label>
                            <input type="text" v-model="form.monthly_transaction_code" id="trans_code" class="form-modal mb-3">
                        </div>

                        <div class="col">
                            <label for="address" class="label-modal"> Pilih Bank</label>
                            <select class="slc-pcg" v-model="form.bank_code">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="data.bank_code" v-for="(data,index) in data_bank" :key="index"> {{data.name}} </option>
                            </select>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col">
                            <label for="address" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form.is_publish">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="true"> Aktif </option>
                                <option :value="false"> Tidak Aktif </option>
                            </select>
                        </div>
                    </div>
                    

                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideModalAdd"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Tambah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-add-excel" hide-footer hide-header title="add-excel">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Tambah Transaction</div>
                
                <form enctype="multipart/form-data" @submit.prevent="addExcel">

                     <div class="row mb-3">
                        <div class="col">
                            <label for="address" class="label-modal"> Pilih Bank</label>
                            <select class="slc-pcg" v-model="form.bank_code">
                                <option value="" selected disabled> Pilih Bank </option>
                                <option :value="data.bank_code" v-for="(data,index) in data_bank" :key="index"> {{data.name}} </option>
                            </select>
                        </div>

                        <div class="col">
                            <label for="address" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form.is_publish">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="true"> Aktif </option>
                                <option :value="false"> Tidak Aktif </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label class="label-modal"> Import </label>
                            <label for="file" class="btn-add ml-2">Import Excel </label>
                            <input type="file" id="file" @change="_change($event)" hidden>
                        </div>
                    </div>

                    <div class="counter-number mt-5"> Proses Data : {{c_number}} / {{data_excel.length}}</div>

                    <div v-if="error_code == '1005'" class="error-code mt-3">Data Sudah Ada</div>
                    <div v-if="error_code == '1006'" class="error-code mt-3">Kode Bank Salah</div>
                    <div v-if="error_code == '500'" class="error-code mt-3">Data Salah</div>
                    <div v-if="flag_done" class="done-code mt-3">Data Sudah Selesai</div>

                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideAddExcel"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Tambah </button>
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>

import axios from 'axios';
import XLSX from 'xlsx';

export default {
    name: 'Transaction',
    data() {
        return {
            currentPage: 1,
            perPage:10,
            rows:0,
            offset:0,
            c_number:0,

            flag_done :false,
            data_excel:[],
            error_code:'',

            data:'',
            data_bank: '',
            bank_code:'',
            data_modal:'',
            id_:'',
            form_edit: {
                // monthly_transaction:'',
                // monthly_transaction_en:'',
                // is_publish: false
                monthly_transaction_code:'',
                bank_code:'',
            },
            form: {
                monthly_transaction:'',
                monthly_transaction_en:'',
                monthly_transaction_code:'',
                bank_code:'',
                is_publish: false,
            },

            base_img: process.env.VUE_APP_URL_IMG,
        }
    },

    methods: {
        async showAddExcel() {
            this.$refs['my-modal-add-excel'].show()
        },

        hideAddExcel() {
            this.$refs['my-modal-add-excel'].hide()
        },

        async addExcel() {

            // this.flag_done = false
            for(let i=0; i<this.data_excel.length; i++) {
                this.form.monthly_transaction_code = this.data_excel[i][0]
                this.form.monthly_transaction = this.data_excel[i][1]
                this.form.monthly_transaction_en = this.data_excel[i][2]
                // console.log(this.form)
                this.c_number = i

                try {
                    const hit = await axios.post('/v1/operator/monthly_transaction', this.form)
                    console.log(hit)

                    if(hit.data.data.status_code == '1005') {
                        this.error_code = hit.data.data.status_code
                    } else {
                        this.error_code = ''
                    }
                } 
                catch(e) {
                    e.response
                    this.error_code = '500'
                }
            }

            this.flag_done = true
            this.c_number = this.data_excel.length
            // window.location.href = '/operator/province' 
            // console.log('end')
        },

        _change(evt) {
			const files = evt.target.files;
			if(files && files[0]) 
            this._file(files[0]);
		},

        _file(file) {
			/* Boilerplate to set up FileReader */
			const reader = new FileReader();
			reader.onload = (e) => {
				/* Parse data */
				const ab = e.target.result;
				const wb = XLSX.read(new Uint8Array(ab), {type:'array'});

				/* Get first worksheet */
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];

				/* Convert array of arrays */
				const data = XLSX.utils.sheet_to_json(ws, {header:1,blankrows: false});
                // console.log(data)
                
				/* Update state */

                // shift row in array 
                // data.shift();
                data.shift();
				this.data_excel = data;
                console.log(this.data_excel);
                
				// this.cols = make_cols(ws['!ref']);
			};
			reader.readAsArrayBuffer(file);
		},

        async changePage() {

            if(this.currentPage == 1) {
                this.offset = 0
            } else {
                this.offset = (this.currentPage -1) * this.perPage
            }
            // console.log(this.offset, this.currentPage)
            const res = await axios.get('v1/operator/monthly_transaction/'+this.bank_code+'?limit='+this.perPage+'&offset='+this.offset)
            this.data = res.data.data.monthly_transactions
            console.log(res)
        },

        async changeBank() {
            try {
                 const res = await axios.get('v1/operator/monthly_transaction/'+this.bank_code+'?limit='+ this.perPage+'&offset='+this.offset)
                this.data = res.data.data.monthly_transactions
                this.rows = res.data.data.total
                console.log(this.data)
            }
            catch(e) {
                console.log(e)
            }
        },

        async showModalDetail(data) {
            this.$refs['my-modal-detail'].show()
            this.data_modal = data
        },

        hideModalDetail() {
            this.$refs['my-modal-detail'].hide()
        },

        showModalEdit(data) {
            console.log(data)
            this.$refs['my-modal-edit'].show()
            // this.form_edit.monthly_transaction = data.monthly_transaction
            // this.form_edit.monthly_transaction_en = data.monthly_transaction_en
            // this.form_edit.is_publish = data.is_publish
            this.form_edit.monthly_transaction_code = data.monthly_transaction_code
            this.form_edit.bank_code = data.bank_code
            this.id_ = data._id
        },

        hideModalEdit() {
            this.$refs['my-modal-edit'].hide()
        },

        async edit() {

            console.log(this.form_edit)

            try {
                const hit = await axios.put('v1/operator/monthly_transaction/'+this.id_, this.form_edit)
                console.log(hit)

                if(!hit.data.iserror) {
                    window.location.href = '/operator' 
                }
            } 
            catch(e) {

            }
        },

        showModalAdd() {
            this.$refs['my-modal-add'].show()
        },

        hideModalAdd() {
            this.$refs['my-modal-add'].hide()
        },

        async add() {

            console.log(this.form)

            try {
                const hit = await axios.post('/v1/operator/monthly_transaction', this.form)
                console.log(hit)

                if(!hit.data.iserror) {
                    window.location.href = '/operator' 
                }
            } 
            catch(e) {

            }
        }


    },

    async mounted() {

    // data bank
        try{
            const res = await axios.get('/v1/operator/bank')
            this.data_bank = res.data.data.bank
            console.log(res)
        }
        catch(e){

        }
    }
}
</script>

<style scoped>

tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.view-logo {
    /* background: rgba(0, 155, 60, 0.1); */
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #009B3C;
    cursor: pointer;
    padding: 10px;
}

.btn-edit {
    background: #FF6F00;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.title-menu {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* line-height: 49px; */
    color: #292b3f;
    margin-bottom: 45px;
}

.wrap-filter {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrap-table {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 50px;
}

.t-pcg {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #292b3f;
    display: inline-block;
    margin-right: 10px;
}

.slc-pcg {
  padding: 5px 10px;
  width: 172px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px #f4f3f9;
  background-color: #f4f3f9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #292b3f;
  font-size: 13px;
  margin-right: 10px;
}

</style>