<template>
    <div>
        <div class="title-menu"> Utility </div>

        <div class="wrap-filter">
            <div class="btn-add" @click="showModalAdd()"> Tambah </div>
        </div>

        <div class="wrap-table">
            <table class="table table-striped table-hover table-borderless">
                <thead >
                    <tr style="height: 23px">
                        <th scope="col" class="text-center">No</th>
                        <th scope="col" class="text-left">Nama</th>
                        <th scope="col" class="text-center">Status</th>
                        <th scope="col" class="text-center" style="width:20%">Logo</th>
                        <th colspan="2" class="text-center" style="width:30%">Aksi</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data_res,index) in data" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td scope="row" class="text-left">{{data_res.name}} </td>
            
                        <td scope="row" class="text-center">{{data_res.is_publish ? 'Aktif' : 'Tidak Aktif'}} </td>
                        
                        <td class="text-center"> 
                           <div class="text-center btn-detail" @click="showModalLogo(data_res)"> Lihat Logo  </div> 
                        </td>
                        
                        <td class="text-center">
                            <div class="text-center btn-detail" @click="showModalDetail(data_res)"> Lihat Detail </div>
                        </td>

                        <td class="text-center">
                            <div class="text-center btn-edit" @click="showModalEdit(data_res)"> Ubah </div>
                        </td>
                    
                    </tr>

                </tbody>
            </table>
        </div> 

        <b-modal ref="my-modal-view" hide-footer hide-header title="lihat logo">
            <div class="wrap-modal-view">
                <div class="row">
                        <img :src= base_img+data_modal.logo class="img-doc-view" v-if="data_modal">
                        <img src="" class="img-doc-view" v-else>
                </div>
                <span class="close-modal" @click="hideModalLogo"> X </span>
            </div>
        </b-modal>

        <b-modal ref="my-modal-detail" hide-footer hide-header title="lihat detail">
            <div class="wrap-modal-view">
                <div class="title-modal mb-5"> Detail Utility</div>

                     <div class="row">
                        <div class="col-4">
                            <label for="name" class="label-modal"> Nama</label>
                        </div>

                        <div class="col-8">
                            <label for="name" class="label-modal bold">{{data_modal.name}}</label>
                        </div>
                    </div>

                     <div class="row">
                        <div class="col-4">
                            <label for="name" class="label-modal"> Tipe future</label>
                        </div>

                        <div class="col-8">
                            <ul v-for="(data,index) in data_modal.futures_type" :key="index">
                                <li class="list-data-modal"> {{data.name}}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <label for="name" class="label-modal"> Status Utility</label>
                        </div>

                        <div class="col-8">
                            <label for="name" class="label-modal bold">{{data_modal.is_publish ? 'Aktif' : 'Tidak Aktif'}}</label>
                        </div>
                    </div>

                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideModalDetail"> Tutup</div>
                    </div>
            </div>
        </b-modal>

        <b-modal ref="my-modal-edit" hide-footer hide-header title="edit">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Ubah Utility</div>
                
                <form @submit.prevent="edit">
                    <label for="name" class="label-modal"> Nama</label>
                    <input type="text" v-model="form_edit.name" id="name" class="form-modal mb-3">

                    <label for="address" class="label-modal"> Status Utility</label>
                    <select class="slc-pcg" v-model="form_edit.is_publish">
                        <option value="" disabled> Pilih Status </option>
                        <option value="true"> Aktif </option>
                        <option value="false"> Tidak Aktif </option>
                    </select>

                    <div class="mt-3">
                        <label class="label-modal bold mb-2"> Logo </label> 
                        <div class="wrap-img-city"> 
                            <input type="file" id="foto" @input="editLogo($event.target.files[0])" class="input-form-foto-modal" hidden>
                            <label for="foto"> 
                                <img v-if="form_edit" :src="base_img + form_edit.logo" class="detail-foto-modal">
                                <img v-else src="/assets/default.png" class="detail-foto-modal">
                            </label>
                        </div>
                    </div>

                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalEdit"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Ubah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-add" hide-footer hide-header title="add">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Tambah Utility</div>
                
                <form enctype="multipart/form-data" @submit.prevent="add">
                    <label for="name" class="label-modal"> Nama</label>
                    <input type="text" v-model="form.name" id="name" class="form-modal mb-3">

                    <div class="row mb-3">
                        <div class="col">
                            <label for="name" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form.is_publish">
                                <option value="" selected disabled> Pilih Tipe </option>
                                <option value="true"> Aktif </option>
                                <option value="false"> Tidak Aktif </option>
                            </select>
                        </div>

                        <div class="col">
                            <label for="name" class="label-modal"> Pilih Future</label>
                            <b-form-checkbox-group
                                v-model="form.futures_type"
                                :options="options"
                                name="flavour-2a"
                                stacked
                            ></b-form-checkbox-group>
                        </div>
                    </div>

                    <div>
                        <label class="label-modal bold mb-2"> Logo </label> 
                        <div class="wrap-img-city"> 
                            <input type="file" id="foto" @input="addLogo($event.target.files[0])" class="input-form-foto-modal" hidden>
                            <label for="foto"> 
                                <!-- <img v-if="form" :src="base_img + form.logo" class="detail-foto-modal"> -->
                                <img src="/assets/default.png" class="detail-foto-modal">
                            </label>
                        </div>
                    </div>

                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalAdd"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Tambah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'Utility',
    data() {
        return {
            data:'',
            data_modal:'',
            id_:'',
            options:[],
            futures:'',
            form_edit: {
                name:'',
                is_publish:'',
                logo:''
            },
            form: {
                name:'',
                is_publish:'',
                logo:'',
                futures_type:[]
            },

            base_img: process.env.VUE_APP_URL_IMG,
        }
    },

    methods: {
        
        showModalDetail(data) {
            this.$refs['my-modal-detail'].show()
            this.data_modal = data
        },

        hideModalDetail() {
            this.$refs['my-modal-detail'].hide()
        },

        showModalLogo(data) {
            this.$refs['my-modal-view'].show()
            this.data_modal = data
        },

        hideModalLogo() {
            this.$refs['my-modal-view'].hide()
        },

        showModalEdit(data) {
            // console.log(data)
            this.$refs['my-modal-edit'].show()
            this.form_edit.name = data.name
            this.form_edit.is_publish = data.is_publish
            this.form_edit.logo = data.logo
            this.id_ = data._id
        },

        hideModalEdit() {
            this.$refs['my-modal-edit'].hide()
        },

        editLogo(data) {
            this.form_edit.logo = data
        },

        async edit() {
            let fd = new FormData()
            
            fd.append('name', this.form_edit.name)
            fd.append('is_publish', this.form_edit.is_publish)
            fd.append('logo', this.form_edit.logo)

            try {
                const hit = await axios.put('v1/admin/utility/'+this.id_, fd)
                console.log(hit)

                if(!hit.iserror) {
                    window.location.href = '/utility' 
                }
            } 
            catch(e) {

            }
        },

        showModalAdd() {
            this.$refs['my-modal-add'].show()
        },

        hideModalAdd() {
            this.$refs['my-modal-add'].hide()
        },

        addLogo(data) {
            console.log(data)
            this.form.logo = data
        },

        async add() {
            
            let fd = new FormData()
            
            fd.append('name', this.form.name)
            fd.append('is_publish', this.form.is_publish)
            fd.append('logo', this.form.logo)

            if(this.form.futures_type) {
                for(let i = 0; i < this.form.futures_type.length; i++) {
                let type = this.form.futures_type[i]

                fd.append('futures_type[' + [i] + ']', type)
                }
            }

            try {
                const hit = await axios.post('/v1/admin/utility', fd)
                console.log(hit)

                if(!hit.iserror) {
                    window.location.href = '/utility' 
                }
            } 
            catch(e) {

            }
        }


    },

    async mounted() {

        // data utility 
        try {
            const res = await axios.get('/v1/admin/utility')
            this.data = res.data.data
            console.log(res)
        }
        catch(e){
            console.log(e)
        }

        // data futures
        try {
            const res = await axios.get('/v1/admin/futures_trading')
            this.futures = res.data.data
            for(let future of res.data.data) {
                this.options.push( 
                    {
                        text: future.name,
                        value: future._id
                    }
                )
            }
            console.log(res)
        } 
        catch(e) {

        }
    }
}
</script>

<style scoped>

tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.list-data-modal {
    margin-left: 17px;
    font-size: 14px;
    font-weight: 600;
    list-style: inherit;
    padding-left: 5px;
}

.view-logo {
    /* background: rgba(0, 155, 60, 0.1); */
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #009B3C;
    cursor: pointer;
    padding: 10px;
}

.btn-edit {
    background: #FF6F00;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.title-menu {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* line-height: 49px; */
    color: #292b3f;
    margin-bottom: 45px;
}

.wrap-filter {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrap-table {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 50px;
}

.t-pcg {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #292b3f;
    display: inline-block;
    margin-right: 10px;
}

.slc-pcg {
  padding: 5px 10px;
  width: 172px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px #f4f3f9;
  background-color: #f4f3f9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #292b3f;
  font-size: 13px;
  margin-right: 10px;
}

</style>