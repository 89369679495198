<template>
    <div>
        <input type="checkbox" id="check">
        <!--header area start-->
        <div class="header-top">
            <label class="label-header" for="check">
                <!-- <i class="fas fa-bars" id="sidebar_btn"></i> -->
                <!-- <div class="fas fa-bars" id="sidebar_btn"> x</div> -->
                <b-icon icon="border-width" class="fas fa-bars" id="sidebar_btn"></b-icon>
            </label>
            <div class="left_area">
                <div> Monitoring </div>
            </div>
            <div class="right_area">
                <a @click="logout()" class="logout_btn">Logout</a>
            </div>
        </div>
        <!--header area end-->

        <!--mobile navigation bar start-->
        <div class="mobile_nav">
            <div class="nav_bar">
                <!-- <img src="1.png" class="mobile_profile_image" alt=""> -->

                <b-icon icon="border-width" variant="success" class="nav_btn" @click="toggleSidebar"></b-icon>
                <!-- <i class="fa fa-bars nav_btn"> X </i> -->
            </div>
            <div class="mobile_nav_items" :class="{'active': isAddClass}">
                <a href="#"><span>Service</span></a>                
            </div>
        </div>
        <!--mobile navigation bar end-->

        <!--sidebar start-->
        <div class="sidebar">
            <router-link to="/monitoring">
              <b-icon icon="credit-card-fill" class="icon-sidebar"></b-icon>
              <span>Service </span>
            </router-link>
        </div>

        <!--sidebar end-->

        <div class="content">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SidebarMenu',
    data() {
        return {
            isAddClass: true
        }
    },

    methods: {
        toggleSidebar() {
            this.isAddClass = !this.isAddClass
            console.log('ok')
        },
        logout() {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('role');
          window.location.href = '/login' 
        }
    }
}
</script>

<style scoped>

body{
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.label-header {
    display: initial !important;
}

.header-top{
  z-index: 1;
  position: fixed;
  background: #22242A;
  padding: 20px;
  width: calc(100% - 0%);
  top: 0;
  left: 0;
  height: 70px;
}

.icon-sidebar {   
    margin-right: 15px;
    position: relative;
    top: 0px;
}

.left_area div{
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 900;
  text-align: left;
}

.left_area span{
  color: #C49732;
}

.logout_btn{
  padding: 10px;
  background: #D50000;
  text-decoration: none;
  float: right;
  margin-top: -40px;
  margin-right: 40px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #fff !important;
  transition: 0.5s;
  cursor: pointer;
}

.logout_btn:hover{
  background: #D50000;
}

.sidebar{
  z-index: 1;
  top: 0;
  background: #1E354A;
  margin-top: 70px;
  padding-top: 30px;
  padding-bottom: 70px;
  position: fixed;
  left: 0;
  width: 250px;
  height: 100vh;
  transition: 0.5s;
  transition-property: left;
  overflow-y: auto;
}

.profile_info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar .profile_info .profile_image{
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin-bottom: 10px;
}

.sidebar .profile_info h4{
  color: #ccc;
  margin-top: 0;
  margin-bottom: 20px;
}

.sidebar a{
    color: #fff;
    display: block;
    width: 100%;
    /* line-height: 60px; */
    text-decoration: none;
    padding: 20px 40px;
    box-sizing: border-box;
    transition: 0.5s;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 10px;
}

.sidebar a:hover{
  background: #C49732;
}

.sidebar i{
  padding-right: 10px;
}

label #sidebar_btn{
  z-index: 1;
  color: #fff;
  position: fixed;
  cursor: pointer;
  left: 300px;
  font-size: 20px;
  margin: 5px 0;
  transition: 0.5s;
  transition-property: color;
}

label #sidebar_btn:hover{
  color: #19B3D3;
}

#check:checked ~ .sidebar{
  left: -185px;
}

#check:checked ~ .sidebar a span{
  display: none;
}

#check:checked ~ .sidebar a{
  font-size: 20px;
  margin-left: 165px;
  width: 100%;
}

.content{
  width: (100% - 250px);
  margin-top: 60px;
  padding: 40px;
  margin-left: 250px;
  /* background: url('../assets/logo.png') no-repeat; */
  background-position: center;
  background-size: cover;
  height: 100vh;
  transition: 0.5s;
}

#check:checked ~ .content{
  margin-left: 60px;
}

#check:checked ~ .sidebar .profile_info{
  display: none;
}

#check{
  display: none;
}

.mobile_nav{
  display: none;
}

.content .card p{
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  opacity: 0.8;
}

/* Responsive CSS */

@media screen and (max-width: 780px){
  .sidebar{
    display: none;
  }

  #sidebar_btn{
    display: none;
  }

  .content{
    margin-left: 0;
    margin-top: 0;
    padding: 10px 20px;
    transition: 0s;
  }

  #check:checked ~ .content{
    margin-left: 0;
  }

  .mobile_nav{
    display: block;
    width: calc(100% - 0%);
  }

  .nav_bar{
    background: #222;
    width: (100% - 0px);
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .nav_bar .mobile_profile_image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .nav_bar .nav_btn{
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    transition: 0.5s;
    transition-property: color;
  }

  .nav_bar .nav_btn:hover{
    color: #19B3D3;
  }

  .mobile_nav_items{
    background: #2F323A;
    display: none;
  }

  .mobile_nav_items a{
    color: #fff;
    display: block;
    text-align: center;
    letter-spacing: 1px;
    line-height: 60px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.5s;
    transition-property: background;
  }

  .mobile_nav_items a:hover{
    background: #19B3D3;
  }

  .mobile_nav_items i{
    padding-right: 10px;
  }

  .active{
    display: block;
  }

  /*.mobile_nav_items{
    background: #2F323A;
    overflow: hidden;
    max-height: 0;
	transition: 0.5s;
	transition-property: max-height;
  }

  .mobile_nav_items a{
    color: #fff;
    display: block;
    text-align: center;
    letter-spacing: 1px;
    line-height: 60px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.5s;
    transition-property: background;
  }

  .mobile_nav_items a:hover{
    background: #19B3D3;
  }

  .mobile_nav_items i{
    padding-right: 10px;
  }

  .active{
    max-height: 1000px;
  }*/
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>