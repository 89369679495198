<template>
    <div>
        <div class="title-menu"> Platform </div>

        <div class="wrap-filter">
            <div class="btn-add" @click="showModalAdd()"> Tambah </div>
        </div>

        <div class="wrap-table">
            <table class="table table-striped table-hover table-borderless">
                <thead >
                    <tr style="height: 23px">
                        <th scope="col" class="text-center">No</th>
                        <th scope="col" class="text-left" style="width:15%">Nama</th>
                        <th scope="col" class="text-center">Kode</th>
                        <th scope="col" class="text-center" style="width:15%">Status</th>
                        <th colspan="2" class="text-center" style="width:30%">Aksi</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data_res,index) in data" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td scope="row" class="text-left">{{data_res.name}} </td>
            
                        <td scope="row" class="text-left">{{data_res.platform_code}} </td>
                        
                        <td class="text-center"> 
                           <div class="text-center"> {{data_res.is_publish ? 'Aktif' : 'Tidak Aktif'}}  </div> 
                        </td>
                        
                        <td class="text-center">
                            <div class="text-center btn-detail" @click="showModalDetail(data_res)"> Lihat Detail </div>
                        </td>

                        <td class="text-center">
                            <div class="text-center btn-edit" @click="showModalEdit(data_res)"> Ubah </div>
                        </td>
                    
                    </tr>

                </tbody>
            </table>
        </div>

        <b-modal ref="my-modal-detail" hide-footer hide-header title="lihat detail">
            <div class="wrap-modal-view" v-if="data_modal">
                <div class="title-modal mb-5"> Detail Platform</div>
                    
                    <div class="wrap-content-overflow">
                        <div class="row">
                            <div class="col-4">
                                <label for="name" class="label-modal"> Nama</label>
                            </div>

                            <div class="col-8">
                                <label for="name" class="label-modal bold">{{data_modal.name}}</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <label for="name" class="label-modal"> Kode Platform</label>
                            </div>

                            <div class="col-8">
                                <label for="name" class="label-modal bold">{{data_modal.platform_code}}</label>
                            </div>
                        </div>

                        <!-- <div class="row">
                            <div class="col-4">
                                <label for="name" class="label-modal"> Tipe future</label>
                            </div>

                            <div class="col-8">
                                <label for="name" class="label-modal bold" v-if="data_modal.futures_type"> {{data_modal.futures_type.name}}</label>
                                <label for="name" class="label-modal" v-else> - </label>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-4">
                                <label for="name" class="label-modal"> Status</label>
                            </div>

                            <div class="col-8">
                                <label for="name" class="label-modal bold">{{data_modal.is_publish ? 'Aktif' : 'Tidak Aktif'}}</label>
                            </div>
                        </div>

                        <!-- <div class="row">
                            <div class="col-4">
                                <label for="name" class="label-modal"> Broker</label>
                            </div>

                            <div class="col-8">
                                <label for="name" class="label-modal bold">{{data_modal.broker_type.name}}</label>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-4">
                                <label for="name" class="label-modal"> Situs Demo</label>
                            </div>

                            <div class="col-8">
                                <label for="name" class="label-modal bold"> <a :href="data_modal.demo_url"> {{data_modal.demo_url}}</a></label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <label for="name" class="label-modal"> Situs Perdagangan</label>
                            </div>

                            <div class="col-8">
                                <label for="name" class="label-modal bold"> <a :href="data_modal.trade_url"> {{data_modal.trade_url}}</a></label>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalDetail"> Tutup</div>
                    </div>
            </div>
        </b-modal>

        <b-modal ref="my-modal-edit" hide-footer hide-header title="edit">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Ubah Platform</div>
                
                <form @submit.prevent="edit">
                    <div class="row">
                        <div class="col">
                            <label for="name" class="label-modal"> Nama</label>
                            <input type="text" v-model="form_edit.name" id="name" class="form-modal mb-3">
                        </div>

                        <div class="col">
                            <label for="company_name" class="label-modal"> Nama Perusahaan</label>
                            <input type="text" v-model="form_edit.company_name" id="company_name" class="form-modal mb-3">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label for="demo_url" class="label-modal"> Situs Demo</label>
                            <textarea type="text" v-model="form_edit.demo_url" id="demo_url" class="form-textarea-modal mb-3"> </textarea>
                        </div>
                        <div class="col">
                            <label for="trade_url" class="label-modal"> Situs Perdagangan</label>
                            <textarea type="text" v-model="form_edit.trade_url" id="trade_url" class="form-textarea-modal mb-3"> </textarea>
                        </div>
                    </div>

                    <div class="col">
                        <label for="code_bank" class="label-modal"> Status</label>
                        <select class="slc-pcg" v-model="form_edit.is_publish">
                            <option value="" selected disabled> Pilih Status </option>
                            <option value="true"> Aktif </option>
                            <option value="false"> Tidak Aktif </option>
                        </select>
                    </div>
                    
                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalEdit"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Ubah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-add" hide-footer hide-header title="add">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Tambah Platform</div>
                
                <form enctype="multipart/form-data" @submit.prevent="add">
                    <div class="wrap-content-overflow">
                        <div class="row mb-3">
                            <div class="col">
                                <label for="name" class="label-modal"> Nama</label>
                                <input type="text" placeholder="Masukan nama platform" v-model="form.name" id="name" class="form-modal mb-3">
                            </div>
                            <div class="col">
                                <label for="company_name" class="label-modal"> Nama Perusahaan</label>
                                <input type="text" placeholder="Masukan nama platform" v-model="form.company_name" id="company_name" class="form-modal mb-3">
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col">
                                <label for="demo" class="label-modal"> Situs Demo</label>
                                <input type="text" v-model="form.demo_url" id="demo" placeholder="ex: https://url.com" class="form-modal mb-3">
                            </div>

                            <div class="col">
                                <label for="trade_url" class="label-modal"> Situs Perdagangan</label>
                                <input type="text" v-model="form.trade_url" id="trade_url" placeholder="ex: https://url.com" class="form-modal mb-3">
                            </div>
                        </div>
                        
                        <div class="row mb-3">
                            <div class="col">
                                <label for="code_bank" class="label-modal"> Tipe Future</label>
                                <select class="slc-pcg" v-model="form.futures_code" @change="futuresType()">
                                    <option value="" selected disabled> Pilih </option>
                                    <option :value="data.futures_code" v-for="(data,index) in data_futures" :key="index"> {{data.name}} </option>
                                </select>
                            </div>

                            <div class="col">
                                <label for="" class="label-modal"> Tipe</label>
                                <select class="slc-pcg" v-model="form.type">
                                    <option value="" selected disabled> Pilih </option> 
                                    <option value="web"> Website </option> 
                                    <option value="mobile"> Mobile </option> 
                                </select>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col">
                                <label for="code_bank" class="label-modal"> Bank</label>
                                <b-form-checkbox-group
                                    v-model="form.bank_type"
                                    :options="options_bank"
                                    name="flavour-2a"
                                    stacked
                                ></b-form-checkbox-group>
                            </div>

                            <div class="col">
                                <label for="code_broker" class="label-modal"> Broker</label>
                                    <b-form-checkbox-group
                                        v-model="form.broker_type"
                                        :options="options_broker"
                                        name="flavour-2a"
                                        stacked
                                    ></b-form-checkbox-group>
                            </div>
                        </div>

                        <div class="row mb-3" >
                            <div class="col">
                                <label for="contract_name" class="label-modal"> Nama Kontrak</label>
                                <input type="text" v-model="form.contract[0].contract_name" id="contract_name" class="form-modal mb-3">
                            </div>

                            <!-- <div class="col">
                                <label for="total_size" class="label-modal"> Ukuran Emas</label>
                                <input type="text" v-model="form.contract.total_size" id="total_size" class="form-modal mb-3">
                            </div> -->

                            <div class="col">
                                <label for="contract_id" class="label-modal"> ID Kontrak</label>
                                <input type="text" v-model="form.contract[0].contract_id" id="contract_id" class="form-modal mb-3">
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col">
                                <label for="" class="label-modal"> Tersedia dalam Trading</label>
                                <select class="slc-pcg" v-model="form.is_publish">
                                    <option value="true"> Iya </option>
                                    <option value=""> Tidak </option>
                                </select>
                            </div>
                        </div>

                        <!-- <div v-if="form.is_trading == 'true'">
                            <div class="row mb-3">
                                <div class="col">
                                    <label for="code_bank" class="label-modal"> Bank</label>
                                    <b-form-checkbox-group
                                        v-model="form.bank_type"
                                        :options="options_bank"
                                        name="flavour-2a"
                                        stacked
                                    ></b-form-checkbox-group>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <label for="contract_name" class="label-modal"> Nama Kontrak</label>
                                    <input type="text" v-model="form.contract.contract_name" id="contract_name" class="form-modal mb-3">
                                </div>

                                <div class="col">
                                    <label for="total_size" class="label-modal"> Ukuran Emas</label>
                                    <input type="text" v-model="form.contract.total_size" id="total_size" class="form-modal mb-3">
                                </div>

                                <div class="col">
                                    <label for="contract_id" class="label-modal"> ID Kontrak</label>
                                    <input type="text" v-model="form.contract.contract_id" id="contract_id" class="form-modal mb-3">
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="row" v-if="form.is_integrate_utility == 'true'">
                            <div class="col">
                                <label for="" class="label-modal"> Utility</label>
                                <b-form-checkbox-group
                                    v-model="form.utility_type"
                                    :options="options_utility"
                                    name="flavour-2a"
                                    stacked
                                ></b-form-checkbox-group>
                            </div>
                        </div> -->
                    </div>


                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalAdd"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Tambah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'Platform',
    data() {
        return {
            data:'',
            data_broker:'',
            data_futures:'',
            data_utility:'',
            data_bank:'',
            data_modal:'',
            options_bank:[],
            options_broker:[],
            id_:'',
            form_edit: {
                name:'',
                company_name:'',
                demo_url:'',
                trade_url:'',
                is_publish:''
            },
            form: {
                name:'',
                company_name:'',
                demo_url:'https://',
                trade_url:'https://',
                bank_type:[],
                futures_code:'',
                broker_type:[],
                contract: [{
                    contract_name:'',
                    contract_id:''
                }],
                type:'', //web,mobile
                is_publish:''
            },

            base_img: process.env.VUE_APP_URL_IMG,
        }
    },

    methods: {

        async showModalDetail(data) {
            
            this.data_modal = data
            console.log(this.data_modal)
            this.$refs['my-modal-detail'].show()

            // try {
            //     let hit = await axios.get('/v1/admin/platform/'+data._id)
            //     console.log(hit)
            // }
            // catch(e){

            // }
        },

        hideModalDetail() {
            this.$refs['my-modal-detail'].hide()
        },

        showModalEdit(data) {
            // console.log(data)
            this.$refs['my-modal-edit'].show()
            this.form_edit.name = data.name
            this.form_edit.company_name = data.company_name
            this.form_edit.demo_url = data.demo_url
            this.form_edit.trade_url = data.trade_url
            this.form_edit.is_publish = data.is_publish
            this.id_ = data._id
        },

        hideModalEdit() {
            this.$refs['my-modal-edit'].hide()
        },

        async edit() {
            // let fd = new FormData()
            
            // fd.append('name', this.form_edit.name)
            // fd.append('address', this.form_edit.address)
            // fd.append('logo', this.form_edit.logo)

            try {
                const hit = await axios.put('v1/admin/platform/'+this.id_, this.form_edit)
                console.log(hit)

                if(!hit.data.iserror) {
                    window.location.href = '/platform' 
                }
            } 
            catch(e) {

            }
        },

        async futuresType(){

            this.options_broker = []
        // data Broker
            try {
                const res = await axios.get('/v1/admin/broker?futures_code='+this.form.futures_code)
                this.data_broker = res.data.data.broker
                for(let list_broker of this.data_broker) {
                    this.options_broker.push(
                        {
                            text: list_broker.name,
                            value: list_broker._id
                        }
                    )
                }
                console.log(this.data_broker)
            }
            catch(e) {
                console.log(e)
            }
        },

        showModalAdd() {
            this.$refs['my-modal-add'].show()
        },

        hideModalAdd() {
            this.$refs['my-modal-add'].hide()
        },

        async add() {
            console.log(this.form)

            // let form_trading = {}

            // if(this.form.is_trading == '' && this.form.is_integrate_utility == '') {
                                
            //     form_trading = {}
            //     form_trading.name = this.form.name
            //     form_trading.address = this.form.address
            //     form_trading.demo_url = this.form.demo_url
            //     form_trading.futures_type = this.form.futures_type
            //     form_trading.broker_type = this.form.broker_type
            //     form_trading.is_publish = Boolean(this.form.is_publish)
            //     form_trading.is_trading = Boolean(this.form.is_trading)
            //     form_trading.is_integrate_utility = Boolean(this.form.is_integrate_utility)
            //     console.log('false semua')
            // }

            // else if(this.form.is_trading == 'true' && this.form.is_integrate_utility == '') {
                
            //     form_trading = {}
            //     form_trading.name = this.form.name
            //     form_trading.address = this.form.address
            //     form_trading.demo_url = this.form.demo_url
            //     form_trading.futures_type = this.form.futures_type
            //     form_trading.broker_type = this.form.broker_type
            //     form_trading.is_publish = Boolean(this.form.is_publish)
            //     form_trading.is_trading = Boolean(this.form.is_trading)
            //     form_trading.is_integrate_utility = Boolean(this.form.is_integrate_utility)
            //     form_trading.contract = this.form.contract
            //     form_trading.bank_type = this.form.bank_type
            //     console.log('trading true')
            // }

            // else if(this.form.is_integrate_utility == 'true' && this.form.is_trading == '') {
            //     form_trading = {}
            //     form_trading.name = this.form.name
            //     form_trading.address = this.form.address
            //     form_trading.demo_url = this.form.demo_url
            //     form_trading.futures_type = this.form.futures_type
            //     form_trading.broker_type = this.form.broker_type
            //     form_trading.is_publish = Boolean(this.form.is_publish)
            //     form_trading.is_trading = Boolean(this.form.is_trading)
            //     form_trading.is_integrate_utility = Boolean(this.form.is_integrate_utility)
            //     form_trading.utility_type = this.form.utility_type
            //     console.log('utility true')
            // }

            // else {
            //     form_trading = this.form
                
            //     form_trading.is_publish = Boolean(this.form.is_publish)
            //     form_trading.is_trading = Boolean(this.form.is_trading)
            //     form_trading.is_integrate_utility = Boolean(this.form.is_integrate_utility)

            //     console.log('true semua')
            // }

            try {
                this.form.is_publish = Boolean(this.form.is_publish)
                const hit = await axios.post('/v1/admin/platform', this.form)
                console.log(hit)

                if(!hit.data.iserror) {
                    window.location.href = '/platform' 
                }
            } 
            catch(e) {

            }
        }


    },

    async mounted() {

    // data list platform
        try {
            const res = await axios.get('/v1/admin/platform')
            this.data = res.data.data.platform
            console.log(res)
        }
        catch(e) {
            console.log(e)
        }

    // data futures
        try {
            const res = await axios.get('/v1/admin/futures_trading')
            this.data_futures = res.data.data.futures_trading
            // console.log(res)
        }
        catch(e) {
            console.log(e)
        }

    // data Bank
        try {
            const res = await axios.get('/v1/admin/bank')
            this.data_bank = res.data.data.bank
            for(let list_bank of this.data_bank) {
                this.options_bank.push(
                    {
                        text: list_bank.name,
                        value: list_bank._id
                    }
                )
            }
            // console.log( this.data_bank)
        }
        catch(e) {
            console.log(e.response)
        }
        
    }
}
</script>

<style scoped>

.wrap-content-overflow {
    min-height: 100px;
    max-height: 370px;
    overflow-x: hidden;
    padding: 0 3px;
    width: 100%;
}

.list-data-modal {
    margin-left: 17px;
    font-size: 14px;
    font-weight: 600;
    list-style: inherit;
    padding-left: 5px;
}

tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.view-logo {
    /* background: rgba(0, 155, 60, 0.1); */
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #009B3C;
    cursor: pointer;
    padding: 10px;
}

.btn-edit {
    background: #FF6F00;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.title-menu {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* line-height: 49px; */
    color: #292b3f;
    margin-bottom: 45px;
}

.wrap-filter {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrap-table {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 50px;
}

.t-pcg {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #292b3f;
    display: inline-block;
    margin-right: 10px;
}

.slc-pcg {
  padding: 5px 10px;
  width: 172px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px #f4f3f9;
  background-color: #f4f3f9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #292b3f;
  font-size: 13px;
  margin-right: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

</style>