<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>

<script>
import axios from 'axios'; 

export default {
  data() {
    return {
      status :''
    }
  },
  created(){
    const token = sessionStorage.getItem('token')
    const loginExpiredTime = Number(localStorage.getItem('loginExpiredTime'))
      if (loginExpiredTime) {
        // if (Date.now() > loginExpiredTime) {
        //   sessionStorage.removeItem('token')
        //   sessionStorage.removeItem('role')
        //   .then(() => {this.$router.push({name: '/'})})}
      }
    if(token) {
      
      this.status = 'idle'
      setInterval(() => {
          this.refreshToken()
        }, 3000)
    }
  },
  methods: {
    // refresh token every 2.5 minutes
    refreshToken() {
      let role = sessionStorage.getItem('role')
      let url = ''
      const intervalRefreshToken = setInterval(() => {
        const token = sessionStorage.getItem('token')
        if (!token) {
          clearInterval(intervalRefreshToken)
        }

        if(role == 1) {
          url = '/v1/admin/refresh'
        }else if(role == 2) {
          url = '/v1/operator/refresh'
        } else if(role == 18){
          url = '/v1/admin-monitoring/auth/refresh'
        }

        let refreshTokenTime = Number(localStorage.getItem('refreshTokenTime'))
        if (refreshTokenTime !== undefined && refreshTokenTime != 0 && Date.now() > refreshTokenTime && this.status != 'loading') {
          this.status = 'loading'
          axios({
            method: 'get',
            url: url
          }).then((response) => {
            this.status = 'success'
            console.log(response)
            const newToken = response.data.data.token
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken
            sessionStorage.setItem('token', newToken)
            // this.$store.commit('SET_TOKEN', newToken)

            localStorage.setItem('refreshTokenTime', Date.now() + 90000) //1 minutes
          }).catch((e) => {
            console.log(e.response)
          })

          // clearInterval(intervalRefreshToken)
        }
      }, 1000)
    }
  }
}
</script>

<style>


@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
}
/* 
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.bold {
  font-weight: 700 !important;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

.counter-number {
    font-size: 12px;
    color: #009B3C;
    text-align: left;
}
.done-code{
    color:#009B3C;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    border-radius: 3px;
    padding: 10px;
    background: rgba(0, 155, 60, 0.1);
}

/* modal  */

.title-modal {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
}

.label-modal {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.form-modal {
    width: 100%;
    border: 1px solid #f0eff5;
    border-radius: 3px;
    height: 35px;
    padding: 0 10px;
    background-color: #f0eff5;
}

.form-textarea-modal {
   width: 100%;
    border: 1px solid #f0eff5;
    border-radius: 3px;
    height: 100px;
    padding: 10px;
    background-color: #f0eff5;
    resize: none;
}

.img-doc-view {
    width: 100%;
    height: 100%;
}

.wrap-modal-view {
    max-width: 100%;
    border-radius: 10px;
    padding: 25px;
    position: relative;
}

.close-modal {
    background: rgba(215, 39, 39, 0.1);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #D72727;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    top: -8px;
    right: -8px;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
}

.btn-edit-modal {
    width: 128px;
    height: 36px;
    border-radius: 5px;
    background-color: #009b3c;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.btn-cancel-modal {
    display: inline-block;
    width: 128px;
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
    border: 1px solid #009b3c;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #009b3c;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
}

.btn-add{
    width: 128px;
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
    background-color: #009b3c;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
    display: inline-block;
}

.btn-detail {
    min-width: 128px;
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #009b3c;
    /* border: 1px solid #009b3c; */
    background: rgba(0, 155, 60, 0.1);
    cursor: pointer;
    display: inline-block;
}

/* img card */

.input-form-foto-modal {
    width: 100px;
    height: 100px;
    line-height: 122px;
    border-radius: 5px;
    /* border: solid 1px #f5b600; */
    background-color: #f4f3f9;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.22px;
    text-align: left;
    color: #F5B600;
    padding-left: 16px;
    cursor: pointer;
}

.detail-foto-modal {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: contain;
    cursor: pointer;
}

/* end img card  */

/* end modal  */

/* loader */

.wrap-loader {
    position: absolute;
    right: 50%;
    top: 20%;
}

button:focus {
    outline: none !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  /* margin: 8px; */
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
