<template>
    <div>
        <div class="title-menu"> District</div>

        <div class="wrap-filter">
            <div class="btn-add" @click="showModalAdd()"> Tambah </div>
            <div class="btn-add ml-3" @click="showAddExcel()"> Import Data </div>

            <div class="float-right">
                <div class="t-pcg">Jenis Bank </div>
                <select class="slc-pcg" v-model="bank_code" @change="changeBank()">
                    <option value="" disabled> Pilih Bank </option>
                    <option :value="data.bank_code" v-for="(data,index) in data_bank" :key="index"> {{data.name}} </option>
                    
                </select>
            </div>
        </div>

        <div class="wrap-table">
            <table class="table table-striped table-hover table-borderless">
                <thead >
                    <tr style="height: 23px">
                        <th scope="col" class="text-center" style="width:10%">No</th>
                        <th scope="col" class="text-left">Kode</th>
                        <th scope="col" class="text-left">District</th>
                        <th scope="col" class="text-center">Status</th>
                        <!-- <th scope="col" class="text-center">Trading</th> -->
                        <th class="text-center" style="width:10%">Aksi</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data_res,index) in data" :key="index">
                        <td class="text-center">{{offset + (index+1)}}</td>
                        <td scope="row" class="text-left">{{data_res.district_code}} </td>
                        <td scope="row" class="text-left">{{data_res.district}} </td>
                        <td scope="row" class="text-center">{{data_res.is_publish ? 'Aktif' : 'Tidak Aktif'}} </td>
                        <!-- <td scope="row" class="text-center">{{data_res.is_trading ? 'Tersedia' : 'Tidak Tersedia'}} </td> -->
                        
                        <!-- <td class="text-center"> 
                           <div class="text-center btn-detail" @click="showModalDetail(data_res)"> Lihat Detail  </div> 
                        </td> -->
                        
                        <td class="text-center">
                            <div class="text-center btn-edit" @click="showModalEdit(data_res)"> Ubah </div>
                        </td>
                    
                    </tr>

                </tbody>
            </table>

            <div class="flex-right mt-5">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    @click.native="changePage"
                ></b-pagination>
            </div>
        </div>

        <b-modal ref="my-modal-edit" hide-footer hide-header title="edit">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Ubah District</div>
                
                <form @submit.prevent="edit">
                    <label for="trans" class="label-modal"> Nama District</label>
                    <input type="text" v-model="form_edit.district" id="trans" class="form-modal mb-3">

                    <div class="row mb-3">
                        <div class="col">
                            <label for="fund_code" class="label-modal"> Kode Regency</label>
                            <input type="text" v-model="form_edit.district_code" id="fund_code" class="form-modal mb-3">

                        </div>

                        <div class="col">
                            <label for="fund_code" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form_edit.is_publish">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="true"> Aktif </option>
                                <option :value="false"> Tidak Aktif </option>
                            </select>
                        </div>
                    </div>

                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalEdit"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Ubah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-add" hide-footer hide-header title="add">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Tambah District</div>
                
                <form enctype="multipart/form-data" @submit.prevent="add">
                    <div class="row mb-3">
                        <div class="col">
                            <label for="trans" class="label-modal"> Nama District</label>
                            <input type="text" v-model="form.district" id="trans" class="form-modal mb-3">
                        </div>
                        <div class="col">
                            <div class="col">
                                <label for="trans_code" class="label-modal"> Kode District</label>
                                <input type="text" v-model="form.district_code" id="trans_code" class="form-modal mb-3">
                            </div>
                        </div>
            
                    </div>
                    

                    <div class="row mb-3">
                        <div class="col">
                            <label for="address" class="label-modal"> Pilih Bank</label>
                            <select class="slc-pcg" v-model="form.bank_code" @change="bankTemp()">
                                <option value="" selected disabled> Pilih Bank </option>
                                <option :value="data.bank_code" v-for="(data,index) in data_bank" :key="index"> {{data.name}} </option>
                            </select>
                        </div>

                        <div class="col">
                            <label for="address" class="label-modal"> Pilih Regency</label>
                            <select class="slc-pcg" v-model="form.regency_code">
                                <option value="" selected disabled> Pilih Regency </option>
                                <option :value="data.regency_code" v-for="(data,index) in data_regency" :key="index"> {{data.regency}} </option>
                            </select>
                        </div>
                    </div>

                    <div class="row mb-3">

                        <!-- <div class="col">
                            <label for="address" class="label-modal"> Status Trading</label>
                            <select class="slc-pcg" v-model="form.is_trading">
                                <option value="" selected disabled> Pilih </option>
                                <option :value="true"> Tersedia </option>
                                <option :value="false"> Tidak Tersedia </option>
                            </select>
                        </div> -->

                        <div class="col">
                            <label for="address" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form.is_publish">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="true"> Aktif </option>
                                <option :value="false"> Tidak Aktif </option>
                            </select>
                        </div>
                    </div>

                    <div v-if="error_code == '1005'" class="error-code mt-3">Data Sudah Ada</div>
                    <div v-if="error_code == '1006'" class="error-code mt-3">Kode Bank Salah</div>
                    <div v-if="error_code == '500'" class="error-code mt-3">Data Salah</div>

                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideModalAdd"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Tambah </button>
                    </div>
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-add-excel" hide-footer hide-header title="add-excel">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Tambah District</div>
                
                <form enctype="multipart/form-data" @submit.prevent="addExcel">                  
                    <div class="row mb-3">
                        <div class="col">
                            <label for="address" class="label-modal"> Pilih Bank</label>
                            <select class="slc-pcg" v-model="form.bank_code" @change="bankTemp()">
                                <option value="" selected disabled> Pilih Bank </option>
                                <option :value="data.bank_code" v-for="(data,index) in data_bank" :key="index"> {{data.name}} </option>
                            </select>
                        </div>

                        <div class="col">
                           <label class="label-modal"> Import </label>
                            <label for="file" class="btn-add ml-2">Import Excel </label>
                            <input type="file" id="file" @change="_change($event)" hidden>
                        </div>
                    </div>

                    <div class="row mb-3">

                        <!-- <div class="col">
                            <label for="address" class="label-modal"> Status Trading</label>
                            <select class="slc-pcg" v-model="form.is_trading">
                                <option value="" selected disabled> Pilih </option>
                                <option :value="true"> Tersedia </option>
                                <option :value="false"> Tidak Tersedia </option>
                            </select>
                        </div> -->

                        <div class="col">
                            <label for="address" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form.is_publish">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="true"> Aktif </option>
                                <option :value="false"> Tidak Aktif </option>
                            </select>
                        </div>
                    </div>

                    <div class="counter-number mt-5"> Proses Data : {{c_number}} / {{data_excel.length}}</div>

                    <div v-if="error_code == '1005'" class="error-code mt-3">Data Sudah Ada</div>
                    <div v-if="error_code == '1006'" class="error-code mt-3">Kode Bank Salah</div>
                    <div v-if="error_code == '500'" class="error-code mt-3">Data Salah</div>
                    <div v-if="flag_done" class="done-code mt-3">Data Sudah Selesai</div>

                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideAddExcel"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Tambah </button>
                    </div>
                </form>
            </div>
        </b-modal>

    </div>
</template>

<script>

import axios from 'axios';
import XLSX from 'xlsx';

export default {
    name: 'District',
    data() {
        return {
            currentPage: 1,
            perPage:10,
            rows:0,
            offset:0,
            c_number:0,

            data_excel:[],
            flag_done :false,
            error_code:'',
            data:'',
            data_bank: '',
            bank_code:'',
            bank_code_temp:'',
            data_modal:'',
            id_:'',
            data_regency:'',
            form_edit: {
                district:'',
                district_code:'',
                is_publish: false,
            },
            form: {
                district:'',
                district_code:'',
                regency_code:'',
                bank_code:'',
                is_publish: false,
                // is_trading: false
            },

            base_img: process.env.VUE_APP_URL_IMG,
        }
    },

    methods: {

        async changePage() {

            if(this.currentPage == 1) {
                this.offset = 0
            } else {
                this.offset = (this.currentPage -1) * this.perPage
            }
            // console.log(this.offset, this.currentPage)
            const res = await axios.get('/v1/operator/district/'+this.bank_code+'?limit='+this.perPage+'&offset='+this.offset)
            this.data = res.data.data.districts
            console.log(res)
        },

        async addExcel() {

            this.flag_done = false
            for(let i=0; i<this.data_excel.length; i++) {
                this.form.district_code = this.data_excel[i][0]
                this.form.regency_code = this.data_excel[i][2]
                this.form.district = this.data_excel[i][1]
                // console.log( this.form.regency_name)
                this.c_number = i
                try {
                    const hit = await axios.post('/v1/operator/district', this.form)
                    console.log(hit)

                    if(hit.data.status == '1005') {
                        this.error_code = hit.data.status
                    } else {
                        this.error_code = ''
                    }
                } 
                catch(e) {
                    e.response
                    this.error_code = '500'
                }
            }

            this.flag_done = true
            this.c_number = this.data_excel.length
            // window.location.href = '/operator/district' 
            // console.log('end')
        },

        _change(evt) {
			const files = evt.target.files;
			if(files && files[0]) this._file(files[0]);
		},

        _file(file) {
			/* Boilerplate to set up FileReader */
			const reader = new FileReader();
			reader.onload = (e) => {
				/* Parse data */
				const ab = e.target.result;
				const wb = XLSX.read(new Uint8Array(ab), {type:'array'});

				/* Get first worksheet */
				const wsname = wb.SheetNames[2];
				const ws = wb.Sheets[wsname];

				/* Convert array of arrays */
				const data = XLSX.utils.sheet_to_json(ws, {header:1,blankrows: false});
                // console.log(data)
                
				/* Update state */
                // data.shift();
                data.shift();
				this.data_excel = data;
                console.log(this.data_excel);
                // let data_code = []
                // for(let i=0; i<data.length; i++) {
                //     data_code.push(data[i][0])
                // }
                // console.log(data_code)
				// this.cols = make_cols(ws['!ref']);
			};
			reader.readAsArrayBuffer(file);
		},

        async showAddExcel() {
            this.$refs['my-modal-add-excel'].show()
        },

        hideAddExcel() {
            this.$refs['my-modal-add-excel'].hide()
        },
        
        async changeBank() {
            try {
                const res = await axios.get('/v1/operator/district/'+this.bank_code+'?limit='+this.perPage+'&offset='+this.offset)
                this.data = res.data.data.districts
                this.rows = res.data.data.total
                console.log(res)
            }
            catch(e) {
                console.log(e)
            }
        },
        async bankTemp() {
            this.bank_code_temp = this.form.bank_code
            
            try {
                const res = await axios.get('/v1/operator/regency/'+this.form.bank_code+'?limit='+this.perPage+'&offset='+this.offset)
                this.data_regency = res.data.data.regencies
                console.log(res)
            }
            catch(e) {
                console.log(e)
            }
        },

        async showModalDetail(data) {
            this.$refs['my-modal-detail'].show()
            this.data_modal = data
        },

        hideModalDetail() {
            this.$refs['my-modal-detail'].hide()
        },

        showModalEdit(data) {
            console.log(data)
            this.$refs['my-modal-edit'].show()
            this.id_ = data._id
            this.form_edit.district = data.district
            this.form_edit.district_code = data.district_code
            this.form_edit.is_publish = data.is_publish
        },

        hideModalEdit() {
            this.$refs['my-modal-edit'].hide()
        },

        async edit() {

            console.log(this.form_edit)

            try {
                const hit = await axios.put('/v1/operator/district/'+this.id_, this.form_edit)
                console.log(hit)

                if(hit.data.status == 200) {
                    window.location.href = '/operator/district' 
                }
            } 
            catch(e) {

            }
        },

        showModalAdd() {
            this.$refs['my-modal-add'].show()
        },

        hideModalAdd() {
            this.$refs['my-modal-add'].hide()
        },

        async add() {
            console.log(this.form)
            // if(!this.form.is_trading) {
            //     this.form.bank_code = '000'
            // } else {
            //     this.form.bank_code = this.bank_code_temp
            // }

            try {
                const hit = await axios.post('/v1/operator/district', this.form)
                console.log(hit)

                if(hit.data.status == 200) {
                    window.location.href = '/operator/district' 
                } else if(hit.data.status == '1005') {
                    this.error_code = hit.data.status
                } else {
                    this.error_code = '500'
                }
            } 
            catch(e) {

            }
        }


    },

    async mounted() {

    // data bank
        try{
            const res = await axios.get('/v1/operator/bank')
            this.data_bank = res.data.data.bank
            console.log(res)
        }
        catch(e){

        }
    }
}
</script>

<style scoped>

.error-code{
    color:red;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
}

tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.view-logo {
    /* background: rgba(0, 155, 60, 0.1); */
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #009B3C;
    cursor: pointer;
    padding: 10px;
}

.btn-edit {
    background: #FF6F00;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.title-menu {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* line-height: 49px; */
    color: #292b3f;
    margin-bottom: 45px;
}

.wrap-filter {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrap-table {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 50px;
}

.t-pcg {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #292b3f;
    display: inline-block;
    margin-right: 10px;
}

.slc-pcg {
  padding: 5px 10px;
  width: 172px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px #f4f3f9;
  background-color: #f4f3f9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #292b3f;
  font-size: 13px;
  margin-right: 10px;
}

</style>