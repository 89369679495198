<template>
    <div>
        <div class="title-menu"> Identity</div>

        <div class="wrap-filter">
            <div class="btn-add" @click="showModalAdd()"> Tambah </div>

            <div class="float-right">
                <div class="t-pcg">Jenis Bank </div>
                <select class="slc-pcg" v-model="bank_code" @change="changeBank()">
                    <option value="" disabled> Pilih Bank </option>
                    <option :value="data.bank_code" v-for="(data,index) in data_bank" :key="index"> {{data.name}} </option>
                    
                </select>
            </div>
        </div>

        <div class="wrap-table">
            <table class="table table-striped table-hover table-borderless">
                <thead >
                    <tr style="height: 23px">
                        <th scope="col" class="text-center">No</th>
                        <th scope="col" class="text-left">Nama Bank</th>
                        <th scope="col" class="text-center">Identitas</th>
                        <th scope="col" class="text-center">Status</th>
                        <th class="text-center" colspan="2" style="width:30%">Aksi</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data_res,index) in data" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td scope="row" class="text-left">{{data_res.bank.name}} </td>
                        <td scope="row" class="text-center">{{data_res.identity}} </td>
                        <td scope="row" class="text-center">{{data_res.is_publish ? 'Aktif' : 'Tidak Aktif'}} </td>
                        
                        <td class="text-center"> 
                           <div class="text-center btn-detail" @click="showModalDetail(data_res)"> Lihat Detail  </div> 
                        </td>
                        
                        <td class="text-center">
                            <div class="text-center btn-edit" @click="showModalEdit(data_res)"> Ubah </div>
                        </td>
                    
                    </tr>

                </tbody>
            </table>
        </div>

        <b-modal ref="my-modal-detail" hide-footer hide-header title="lihat detail">
            <div class="wrap-modal-view" v-if="data_modal">
                <div class="title-modal mb-5"> Detail Identity</div>

                    <div class="row">
                        <div class="col-6">
                            <label for="name" class="label-modal"> Nama Bank</label>
                        </div>

                        <div class="col-6">
                            <label for="name" class="label-modal bold">{{data_modal.bank.name}}</label>
                        </div>
                    </div>

                     <div class="row">
                        <div class="col-6">
                            <label for="name" class="label-modal"> Status</label>
                        </div>

                        <div class="col-6">
                            <label for="name" class="label-modal bold">{{data_modal.is_publish ? 'Aktif' : 'Tidak Aktif'}}</label>
                        </div>
                    </div>

                     <div class="row">
                        <div class="col-6">
                            <label for="name" class="label-modal"> Status Trading</label>
                        </div>

                        <div class="col-6">
                            <label for="name" class="label-modal bold">{{data_modal.is_trading ? 'Tersedia' : 'Tidak Tersedia'}}</label>
                        </div>
                    </div>

                     <div class="row">
                        <div class="col-6">
                            <label for="name" class="label-modal"> Identitas</label>
                        </div>

                        <div class="col-6">
                            <label for="name" class="label-modal bold">{{data_modal.identity}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <label for="name" class="label-modal"> Kode Identitas</label>
                        </div>

                        <div class="col-6">
                            <label for="name" class="label-modal bold">{{data_modal.identity_code}}</label>
                        </div>
                    </div>

                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideModalDetail"> Tutup</div>
                    </div>
            </div>
        </b-modal>

        <b-modal ref="my-modal-edit" hide-footer hide-header title="edit">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Ubah Identity</div>
                
                <form @submit.prevent="edit">
                    <label for="trans" class="label-modal"> Identitas</label>
                    <input type="text" v-model="form_edit.identity" id="trans" class="form-modal mb-3">

                    <label for="trans_en" class="label-modal"> Identitas (English)</label>
                    <input type="text" v-model="form_edit.identity_en" id="trans_en" class="form-modal mb-3">

                    <div class="row mb-3">
                        <div class="col">
                            <label for="identity_code" class="label-modal"> Kode Identitas</label>
                            <input type="text" v-model="form_edit.identity_code" id="identity_code" class="form-modal mb-3">

                        </div>

                        <div class="col">
                            <label for="fund_code" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form_edit.is_publish">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="true"> Aktif </option>
                                <option :value="false"> Tidak Aktif </option>
                            </select>
                        </div>
                    </div>

                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalEdit"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Ubah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-add" hide-footer hide-header title="add">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Tambah Identity</div>
                
                <form enctype="multipart/form-data" @submit.prevent="add">
                    <label for="trans" class="label-modal"> Identitas</label>
                    <input type="text" v-model="form.identity" id="trans" class="form-modal mb-3">

                    <label for="trans_en" class="label-modal"> Identitas (English)</label>
                    <input type="text" v-model="form.identity_en" id="trans_en" class="form-modal mb-3">

                    <div class="row mb-3">
                        <div class="col">
                            <label for="trans_code" class="label-modal"> Kode Identitas</label>
                            <input type="text" v-model="form.identity_code" id="trans_code" class="form-modal mb-3">
                        </div>

                        <div class="col">
                            <label for="address" class="label-modal"> Pilih Bank</label>
                            <select class="slc-pcg" v-model="form.bank_type">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="data._id" v-for="(data,index) in data_bank" :key="index"> {{data.name}} </option>
                            </select>
                        </div>
                    </div>

                    <div class="row mb-3">

                        <div class="col">
                            <label for="address" class="label-modal"> Status Trading</label>
                            <select class="slc-pcg" v-model="form.is_trading">
                                <option value="" selected disabled> Pilih </option>
                                <option :value="true"> Tersedia </option>
                                <option :value="false"> Tidak Tersedia </option>
                            </select>
                        </div>

                        <div class="col">
                            <label for="address" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form.is_publish">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="true"> Aktif </option>
                                <option :value="false"> Tidak Aktif </option>
                            </select>
                        </div>
                    </div>
                    

                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideModalAdd"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Tambah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'Identity',
    data() {
        return {
            data:'',
            data_bank: '',
            bank_code:'',
            data_modal:'',
            id_:'',
            form_edit: {
                identity:'',
                identity_en:'',
                identity_code:'',
                is_publish: false
            },
            form: {
                identity:'',
                identity_en:'',
                identity_code:'',
                bank_type:'',
                is_publish: false,
                is_trading: false
            },

            base_img: process.env.VUE_APP_URL_IMG,
        }
    },

    methods: {
        
        async changeBank() {
            try {
                 const res = await axios.get('v1/operator/identity/'+this.bank_code)
                this.data = res.data.data
                console.log(res)
            }
            catch(e) {
                console.log(e)
            }
        },

        async showModalDetail(data) {
            this.$refs['my-modal-detail'].show()
            this.data_modal = data
        },

        hideModalDetail() {
            this.$refs['my-modal-detail'].hide()
        },

        showModalEdit(data) {
            console.log(data)
            this.$refs['my-modal-edit'].show()
            this.form_edit.identity = data.identity
            this.form_edit.identity_en = data.identity_en
            this.form_edit.identity_code = data.identity_code
            this.form_edit.is_publish = data.is_publish
            this.id_ = data._id
        },

        hideModalEdit() {
            this.$refs['my-modal-edit'].hide()
        },

        async edit() {

            console.log(this.form_edit)

            try {
                const hit = await axios.put('v1/operator/identity/'+this.id_, this.form_edit)
                console.log(hit)

                if(!hit.iserror) {
                    window.location.href = '/operator/identity' 
                }
            } 
            catch(e) {

            }
        },

        showModalAdd() {
            this.$refs['my-modal-add'].show()
        },

        hideModalAdd() {
            this.$refs['my-modal-add'].hide()
        },

        async add() {

            console.log(this.form)

            try {
                const hit = await axios.post('/v1/operator/identity', this.form)
                console.log(hit)

                if(!hit.iserror) {
                    window.location.href = '/operator/identity' 
                }
            } 
            catch(e) {

            }
        }


    },

    async mounted() {

    // data transaction
        // try{
        //     const res = await axios.get('v1/operator/monthly_transaction/')
        //     this.data = res.data.data
        //     console.log(res)
        // }
        // catch(e){

        // }

    // data bank
        try{
            const res = await axios.get('/v1/operator/bank')
            this.data_bank = res.data.data
            console.log(res)
        }
        catch(e){

        }
    }
}
</script>

<style scoped>

tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.view-logo {
    /* background: rgba(0, 155, 60, 0.1); */
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #009B3C;
    cursor: pointer;
    padding: 10px;
}

.btn-edit {
    background: #FF6F00;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.title-menu {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* line-height: 49px; */
    color: #292b3f;
    margin-bottom: 45px;
}

.wrap-filter {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrap-table {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 50px;
}

.t-pcg {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #292b3f;
    display: inline-block;
    margin-right: 10px;
}

.slc-pcg {
  padding: 5px 10px;
  width: 172px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px #f4f3f9;
  background-color: #f4f3f9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #292b3f;
  font-size: 13px;
  margin-right: 10px;
}

</style>