<template>
    <div>
        <div class="title-menu"> Service</div>

        <div class="wrap-filter">
            <div class="btn-add" @click="showModalAdd()"> Tambah </div>
        </div>

        <div class="wrap-table">
            <table class="table table-striped table-hover table-borderless">
                <thead >
                    <tr style="height: 23px">
                        <th scope="col" class="text-center">No</th>
                        <th scope="col" class="text-left">Nama Service</th>
                        <th scope="col" class="text-center">Kode Service</th>
                        <th scope="col" class="text-center">Status</th>
                        <th scope="col" class="text-center">Deskripsi</th>
                        <th class="text-center" colspan="2" style="width:30%">Aksi</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data_res,index) in data" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td scope="row" class="text-left">{{data_res.service}} </td>
                        <td scope="row" class="text-center">{{data_res.service_code}} </td>
                        <td scope="row" class="text-center">{{data_res.status}} </td>
                        <td scope="row" class="text-center">{{data_res.description}} </td>
                        
                        <td class="text-center">
                            <div class="text-center btn-edit" @click="showModalEdit(data_res)"> Ubah </div>
                        </td>
                    
                    </tr>

                </tbody>
            </table>
        </div>

        <b-modal ref="my-modal-edit" hide-footer hide-header title="edit">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Ubah Service</div>
                
                <form @submit.prevent="edit">
                    <label for="trans" class="label-modal"> Nama Service</label>
                    <input type="text" v-model="form_edit.service" id="trans" class="form-modal mb-3">

                    <label for="trans_en" class="label-modal"> Status</label>
                    <select class="slc-pcg" v-model="form_edit.status">
                        <option value="on"> On</option>
                        <option value="maintenance"> Maintenance </option>
                        <option value="off"> Off </option>
                    </select>

                    <label for="trans_en" class="label-modal"> Deskripsi</label>
                    <input type="text" v-model="form_edit.description" id="trans_en" class="form-modal mb-3">

                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalEdit"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Ubah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-add" hide-footer hide-header title="add">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Tambah Service</div>
                
                <form enctype="multipart/form-data" @submit.prevent="add">
                <div class="row">
                    <div class="col">
                        <label for="trans" class="label-modal"> Nama Service</label>
                        <input type="text" @keypress="formatService" v-model="form.service" id="trans" class="form-modal mb-3">
                    </div>
                    <div class="col">
                        <div>
                            <label for="address" class="label-modal"> Status</label>
                            <select class="slc-pcg" v-model="form.status">
                                <option value="" selected disabled> Pilih Status </option>
                                <option value="on"> On </option>
                                <option value="off"> Off </option>
                                <option value="maintenance"> Maintenance </option>
                            </select>
                        </div>
                    </div>
                </div>
                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideModalAdd"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Tambah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'Gender',
    data() {
        return {
            data:'',
            data_bank: '',
            bank_code:'',
            data_modal:'',
            id_:'',
            form_edit: {
                service:'',
                description:'',
                status: ''
            },
            form: {
                service:'',
                status:'',
            },

            base_img: process.env.VUE_APP_URL_IMG,
        }
    },
    created() {
        this.getServiceAll()
    }, 

    methods: {
        
        async getServiceAll() {
            try {
                const res = await axios.get('/v1/service')
                this.data = res.data.data.body
                console.log(this.data)
            }
            catch(e) {
                console.log(e)
            }
        },

        async showModalDetail(data) {
            this.$refs['my-modal-detail'].show()
            this.data_modal = data
        },

        hideModalDetail() {
            this.$refs['my-modal-detail'].hide()
        },

        showModalEdit(data) {
            console.log(data)
            this.$refs['my-modal-edit'].show()
            this.form_edit.service = data.service
            this.form_edit.description = data.description
            // this.form_edit.service_code = data.service_code
            this.form_edit.status = data.status
            this.id_ = data._id
        },

        hideModalEdit() {
            this.$refs['my-modal-edit'].hide()
        },

        async edit() {

            console.log(this.form_edit)

            try {
                const hit = await axios.put('/v1/service/'+this.id_, this.form_edit)
                console.log(hit)

                if(!hit.iserror) {
                    window.location.href = '/monitoring' 
                }
            } 
            catch(e) {

            }
        },

        showModalAdd() {
            this.$refs['my-modal-add'].show()
        },

        hideModalAdd() {
            this.$refs['my-modal-add'].hide()
        },

        async add() {

            console.log(this.form)

            try {
                const hit = await axios.post('/v1/service', this.form)
                console.log(hit)

                if(!hit.iserror) {
                    window.location.href = '/monitoring' 
                }
            } 
            catch(e) {

            }
        },

        formatService(event){
            const pattern =  /^[a-zA-Z0-9\s,.-]*$/;
            let inputChar = String.fromCharCode(event.charCode);
            if (event.keyCode != 8 && !pattern.test(inputChar)) {
                event.preventDefault();
            }
        }


    },

    async mounted() {
    }
}
</script>

<style scoped>

tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.view-logo {
    /* background: rgba(0, 155, 60, 0.1); */
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #009B3C;
    cursor: pointer;
    padding: 10px;
}

.btn-edit {
    background: #FF6F00;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.title-menu {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* line-height: 49px; */
    color: #292b3f;
    margin-bottom: 45px;
}

.wrap-filter {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrap-table {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 50px;
}

.t-pcg {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #292b3f;
    display: inline-block;
    margin-right: 10px;
}

.slc-pcg {
  padding: 5px 10px;
  width: 172px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px #f4f3f9;
  background-color: #f4f3f9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #292b3f;
  font-size: 13px;
  margin-right: 10px;
}

</style>