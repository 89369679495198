<template>
    <div class="bg-blue">
        <b-container fluid> 
            <b-row class="mt-7"> 
                <b-col sm="12" lg="4">
                </b-col>

                <b-col sm="12" lg="8" class="side-right">
                    <div class="wrap-box-login">
                        <div class="title-login mb-5">Login </div>
        
                        <form @submit.prevent="login" class="mt-3">
                            <label for="username_operator" class="label-login"> Username</label>
                            <input type="text" v-model="form.username" placeholder="Masukan Username" id="username_operator" class="form-login mb-3">

                            <div style="position:relative;">
                                <label for="password_operator" class="label-login"> Password</label>
                                <input :type=" show_password ? 'text' : 'password'" id="password_operator" v-model="form.password" placeholder="Masukan Password" class="form-login">
                                
                                <span class="show-eye" @click="showPassword" v-if="!show_password"> 
                                    <b-icon icon="eye"></b-icon>
                                </span>

                                <span class="show-eye" @click="showPassword" v-else> 
                                    <b-icon icon="eye-slash"></b-icon>
                                </span>
                            </div>

                            <!-- <b-form-group label="Pilih Role" class="mt-3" v-slot="{ ariaDescribedby }">
                                <b-form-radio v-model="type_role" :aria-describedby="ariaDescribedby" name="some-radios" value="admin">Admin</b-form-radio>
                                <b-form-radio v-model="type_role" :aria-describedby="ariaDescribedby" name="some-radios" value="operator">Operator</b-form-radio>
                                <b-form-radio v-model="type_role" :aria-describedby="ariaDescribedby" name="some-radios" value="monitoring">Monitoring</b-form-radio>
                            </b-form-group> -->
                            
                            <div v-if="error_msg" class="alert-error mt-3" style="color:red"> Email atau password salah! </div>
                            <!-- <div v-else-if="error_msg == 404" class="alert-error mt-3" style="color:red"> Akun Tidak ditemukan </div>
                            <div v-else-if="error_msg == 403" class="alert-error mt-3" style="color:red"> Akses Ditolak </div> -->
                            <div v-else-if="error_msg == 490" class="alert-error mt-3" style="color:red"> Silahkan Pilih Role </div>
                            <div v-else></div>


                            <button class="btn-login mt-5"> 
                            
                                <div class="wrap-loader" v-if="loader"> 
                                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                </div>

                                    Login 
                            </button>
                        </form>

                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>    
</template>

<script>

import axios from 'axios';

export default {
    name: 'LoginComponent',
    data() {
        return {
            form: {
                username:'',
                password:'',
                clientCaptchaKey:null,
            },
            scriptCaptcha:'',

            type_role:'',           
            error_msg:false,
            show_password:false,
            loader: false,
            geolocation:'',
        }
        
    },
    methods: {

        async login() {
            this.error_msg = false
            this.loader = true

            window.grecaptcha.enterprise.ready(() => {
            window.grecaptcha.enterprise.execute('6LcsHsYkAAAAALPK1POPcNxHyJ6YE-ZbbTYC7Zdk', { action: "LOGIN_ADMIN_BEI" })
            .then(async (token) => {
                this.form.clientCaptchaKey = token;
                // console.log(this.form)

            const login = await axios({
                    method: 'POST',
                    url:'/v1/auth/login',
                    data: this.form,
                    headers: {
                        'Latitude': this.geolocation.latitude,
                        'Longitude': this.geolocation.longitude,
                        'City': this.geolocation.city,
                        'Region': this.geolocation.region,
                        'Country': this.geolocation.country,
                        'IP-Address': this.geolocation.userIP,
                    }
                });  
                let tokenClient = login.data.data.token
                // console.log(login)

                sessionStorage.setItem('token', tokenClient);
                sessionStorage.setItem('role', login.data.data.user.role);

                if((!login.data.iserror) && login.data.data.status_code == '00' && login.data.data.user.role == 1) {
                    this.loader = false
                    window.location.href = '/'
                    // this.$router.go('/')
                } else if(login.data.data.user.role == 2){
                    this.loader = false
                    window.location.href = '/operator'
                    // this.$router.go('/operator')
                }
                else {
                    this.loader = false
                    this.error_msg = true
                }

                // for login expired in frontend only
                localStorage.setItem('loginExpiredTime', Date.now() + 900000) //15 minutes in milisecond

                // for refresh token
                localStorage.setItem('refreshTokenTime', Date.now() + 90000) //1 minutes
            
            }).catch((e) => {
                this.loader = false
                console.log(e)
                // let error_res = e.response.status
                this.error_msg = true
                });
            });
        },
        formatNumber(event) {
            const pattern = /[0-9]/;
            let inputChar = String.fromCharCode(event.charCode);
            if (event.keyCode != 8 && !pattern.test(inputChar)) {
                event.preventDefault();
            }
        },
        showPassword() {
            this.show_password = !this.show_password;
        },
        getGeolocation() {
		    return new Promise((resolve, reject) => {
			fetch('https://asia-southeast1-quiet-stacker-245710.cloudfunctions.net/get-user-access-location')
		        .then(result => result.json())
                    .then((output) => {
                        const geolocation = {
                            ...output,
                            latitude: output.cityLatLong.split(',')[0],
                            longitude: output.cityLatLong.split(',')[1]
                        }
                        console.log(geolocation)
                        this.geolocation = geolocation
                        localStorage.setItem('geolocation', JSON.stringify(geolocation))
                        resolve(geolocation)
                    })
			.catch(() => {
				reject()
			})
		})
	    },
        async old(){
            try {   
                if(this.type_role == 'admin') {
                    const login = await axios({
                        method: 'POST',
					    url:'v1/admin/login',
                        data: this.form,
                        headers: {
                            'Latitude': this.geolocation.latitude,
                            'Longitude': this.geolocation.longitude,
                            'City': this.geolocation.city,
                            'Region': this.geolocation.region,
                            'Country': this.geolocation.country,
                            'IP-Address': this.geolocation.userIP,
                        }
                    });  
                    // console.log(login, login.data.data.token)
    
                    sessionStorage.setItem('token', login.data.data.token);
                    sessionStorage.setItem('role', login.data.data.data.role);
    
                    if((!login.data.iserror) && login.data.data.status_code == '00') {
                        this.loader = false
                        window.location.href = '/'
                    } 
                    else {
                        this.loader = false
                        this.error_msg = true
                        // window.location.href = '/login'
                    }
                }

                else if(this.type_role == 'operator') {
                    const login = await axios({
                        method: 'POST',
					    url:'/v1/operator/login',
                        data: this.form,
                        headers: {
                            'Latitude': this.geolocation.latitude,
                            'Longitude': this.geolocation.longitude,
                            'City': this.geolocation.city,
                            'Region': this.geolocation.region,
                            'Country': this.geolocation.country,
                            'IP-Address': this.geolocation.userIP,
                        }
                    });  
                    console.log(login)

                    sessionStorage.setItem('token', login.data.data.token);
                    sessionStorage.setItem('role', login.data.data.data.role);

                    if((!login.data.iserror) && login.data.data.status_code == '00') {
                        this.loader = false
                        window.location.href = '/operator'
                    } 
                    else {
                        this.loader = false
                        this.error_msg = true
                    }
                }
                else {
                    this.loader = false
                    this.error_msg = 490
                }

                // for login expired in frontend only
                localStorage.setItem('loginExpiredTime', Date.now() + 900000) //15 minutes in milisecond

                // for refresh token
                localStorage.setItem('refreshTokenTime', Date.now() + 90000) //1 minutes
            }
            catch(e) {
                this.loader = false
                console.log(e.response)
                // let error_res = e.response.status
                this.error_msg = true
            }
        }
    },
    mounted(){
        this.getGeolocation()
    },
    created() {
        this.scriptCaptcha = document.createElement('script');

        this.scriptCaptcha.src = "https://www.google.com/recaptcha/enterprise.js?render=6LcsHsYkAAAAALPK1POPcNxHyJ6YE-ZbbTYC7Zdk"
        this.scriptCaptcha.async = true;

        document.body.appendChild(this.scriptCaptcha);
    },
    beforeDestroy() {
        document.body.removeChild(this.scriptCaptcha);
    }
}
</script>

<style scoped>

.show-eye {
    position: absolute;
    right: 13px;
    bottom: 5px;
}

.alert-error {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: red;
    text-align: center;
}

.bg-blue {
    height: 100vh;
    background-color: #e6f2ff;
}

.side-right {
    background-color: #FFFFFF;
    height: 100vh;
}

.info-switch {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #000000;
}

.switch-auth {
    color: #1baa56;
    text-decoration: none;
    cursor: pointer;
}

.wrap-box-login {
    max-width: 60%;
    padding: 24px;
    text-align: left;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin: 60px auto;
}

.title-login {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
}

.label-login {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.form-login {
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 3px;
    height: 35px;
    padding: 0 10px;
    background-color: #f9faff;
}

.btn-login {
    width: 100%;
    height: 40px;
    background: #090963;
    border: 1px solid #090963;
    box-sizing: border-box;
    border-radius: 3px;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    color: #FFFFFF;
    position: relative;
    cursor: pointer;
}

::-webkit-input-placeholder { /* Edge */
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
  
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
  
}

::placeholder {
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
  
}

@media only screen and (max-width: 800px) {
  .wrap-box-login {
    max-width: 100%;
    padding: 24px;
    text-align: left;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 1px #e8e8e8;
    margin: 60px auto;
  }
}
</style>