<template>
    <div>
        <div class="title-menu"> Broker </div>

        <div class="wrap-filter">
            <div class="btn-add" @click="showModalAdd()"> Tambah </div>
        </div>

        <div class="wrap-table">
            <table class="table table-striped table-hover table-borderless">
                <thead >
                    <tr style="height: 23px">
                        <th scope="col" class="text-center">No</th>
                        <th scope="col" class="text-left" style="width:15%">Nama</th>
                        <th scope="col" class="text-center">Alamat</th>
                        <!-- <th scope="col" class="text-center" style="width:20%">Logo</th> -->
                        <th colspan="2" class="text-center" style="width:30%">Aksi</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data_res,index) in data" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td scope="row" class="text-left">{{data_res.name}} </td>
            
                        <td scope="row" class="text-left">{{data_res.address}} </td>
                        
                        <!-- <td class="text-center"> 
                           <div class="text-center btn-detail" @click="showModalLogo(data_res)"> Lihat Logo  </div> 
                        </td> -->
                        
                        <td class="text-center">
                            <div class="text-center btn-detail" @click="showModalDetail(data_res)"> Lihat Detail </div>
                        </td>

                        <td class="text-center">
                            <div class="text-center btn-edit" @click="showModalEdit(data_res)"> Ubah </div>
                        </td>
                    
                    </tr>

                </tbody>
            </table>
        </div>

        <b-modal ref="my-modal-view" hide-footer hide-header title="lihat logo">
            <div class="wrap-modal-view">
                <div class="row">
                        <img :src= base_img+data_modal.logo class="img-doc-view" v-if="data_modal">
                        <img src="" class="img-doc-view" v-else>
                </div>
                <span class="close-modal" @click="hideModalLogo"> X </span>
            </div>
        </b-modal>

        <b-modal ref="my-modal-detail" hide-footer hide-header title="lihat detail">
            <div class="wrap-modal-view">
                <div class="title-modal mb-5"> Detail Broker</div>

                    <div class="row">
                        <div class="col-4">
                            <label for="name" class="label-modal"> Nama</label>
                        </div>

                        <div class="col-8">
                            <label for="name" class="label-modal bold">{{data_modal.name}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <label for="name" class="label-modal"> Alamat</label>
                        </div>

                        <div class="col-8">
                            <label for="name" class="label-modal bold">{{data_modal.address}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <label for="name" class="label-modal"> Future</label>
                        </div>

                        <div class="col-8">
                            <label for="name" class="label-modal bold">{{data_modal.futures_trading ? data_modal.futures_trading.name : ''}}</label>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <div class="col-4">
                            <label for="name" class="label-modal"> Tipe future</label>
                        </div>

                        <div class="col-8">
                            <label for="name" class="label-modal bold" v-if="data_modal.futures_type"> {{data_modal.futures_type.name}}</label>
                            <label for="name" class="label-modal" v-else> - </label>
                        </div>
                    </div> -->

                    <!-- <div class="row">
                        <div class="col-4">
                            <label for="name" class="label-modal"> Status WebReg</label>
                        </div>

                        <div class="col-8">
                            <label for="name" class="label-modal bold">{{data_modal.is_web_reg ? 'Tersedia' : 'Tidak Tersedia'}}</label>
                        </div>
                    </div> -->

                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideModalDetail"> Tutup</div>
                    </div>
            </div>
        </b-modal>

        <b-modal ref="my-modal-edit" hide-footer hide-header title="edit">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Ubah Broker</div>
                
                <form @submit.prevent="edit">
                    <label for="name" class="label-modal"> Nama</label>
                    <input type="text" v-model="form_edit.name" id="name" class="form-modal mb-3">

                    <label for="address" class="label-modal"> Alamat</label>
                    <textarea type="text" v-model="form_edit.address" id="address" class="form-textarea-modal mb-3"> </textarea>

                    <div class="row mb-3">
                        <div class="col">
                            <label for="company_name" class="label-modal"> Nama Perusahaan</label>
                            <input type="text" v-model="form_edit.company_name" id="company_name" class="form-modal mb-3">

                        </div>

                        <div class="col">
                            <label for="account_id" class="label-modal"> Akun ID</label>
                            <input type="text" v-model="form_edit.account_id" id="account_id" class="form-modal mb-3">

                        </div>
                    </div>


                    <!-- <div class="mbt-3">
                        <label class="label-modal bold mb-2"> Logo </label> 
                        <div class="wrap-img-city"> 
                            <input type="file" id="foto" @input="editLogo($event.target.files[0])" class="input-form-foto-modal" hidden>
                            <label for="foto"> 
                                <img v-if="form_edit" :src="base_img + form_edit.logo" class="detail-foto-modal">
                                <img v-else src="/assets/default.png" class="detail-foto-modal">
                            </label>
                        </div>
                    </div> -->

                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalEdit"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Ubah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-add" hide-footer hide-header title="add">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Tambah Broker</div>
                
                <form enctype="multipart/form-data" @submit.prevent="add">

                    <div class="row">
                        <div class="col">
                            <label for="name" class="label-modal"> Nama</label>
                            <input type="text" v-model="form.name" id="name" class="form-modal mb-3">
                        </div>

                        <div class="col">
                            <label for="company_name" class="label-modal"> Nama Perusahaan</label>
                            <input type="text" v-model="form.company_name" id="company_name" class="form-modal mb-3">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label for="code_bank" class="label-modal"> Kode Futures</label>
                            <select class="slc-pcg" v-model="form.futures_code">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="data.futures_code" v-for="(data,index) in data_futures" :key="index"> {{data.name}} </option>
                            </select>
                        </div>

                        <div class="col">
                            <label for="account_id" class="label-modal"> Akun ID</label>
                            <input type="text" v-model="form.account_id" id="account_id" class="form-modal mb-3">
                        </div>
                        
                    </div>
                    
                    <label for="address" class="label-modal"> Alamat</label>
                    <textarea type="text" v-model="form.address" id="address" class="form-textarea-modal mb-3"> </textarea>

                    <!-- <div class="row mb-3">
                        <div class="col">
                            <label for="code_bank" class="label-modal"> Status WebReg</label>
                            <select class="slc-pcg" v-model="form.is_web_reg">
                                <option value="" selected disabled> Pilih Status </option>
                                <option value="true"> Tersedia </option>
                                <option value="false"> Tidak Tersedia </option>
                            </select>
                        </div>
                        <div class="col">
                            <label for="code_bank" class="label-modal"> Tipe Future</label>
                            <select class="slc-pcg" v-model="form.futures_type">
                                <option value="" selected disabled> Pilih Status </option>
                                <option :value="data._id" v-for="(data,index) in data_futures" :key="index"> {{data.name}} </option>
                            </select>
                        </div>
                    </div> -->

                    <!-- <div class="row mb-3">
                        <div class="col">
                            <label for="" class="label-modal"> Pilih Features</label>
                            <b-form-checkbox-group
                                v-model="form.feature"
                                :options="options"
                                name="flavour-2a"
                                stacked
                            ></b-form-checkbox-group>
                        </div>

                        <div class="col">
                            <label class="label-modal bold mb-2"> Logo </label> 
                            <div class="wrap-img-city"> 
                                <input type="file" id="foto" @input="addLogo($event.target.files[0])" class="input-form-foto-modal" hidden>
                                <label for="foto"> 
                                    <img src="/assets/default.png" class="detail-foto-modal">
                                </label>
                            </div>
                        </div>
                    </div> -->


                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalAdd"> Tutup</div>
                        <button class="btn-edit-modal" type="submit"> Tambah </button>
                    </div>
                
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'Broker',
    data() {
        return {
            data:'',
            data_futures:'',
            data_modal:'',
            id_:'',
            options:[],
            form_edit: {
                name:'',
                address:'',
                company_name:'',
                futures_code:'',
                account_id:'',
                // logo:'',
                // is_web_reg:'',
                // futures_type:''
            },
            form: {
                name:'',
                address:'',
                company_name:'',
                futures_code:'',
                account_id:'',
                // logo:'',
                // futures_type:'',
                // is_web_reg:'',
                // feature:[]
            },

            base_img: process.env.VUE_APP_URL_IMG,
        }
    },

    methods: {

        showModalDetail(data) {
            this.$refs['my-modal-detail'].show()

            this.data_modal = data
        },

        hideModalDetail() {
            this.$refs['my-modal-detail'].hide()
        },

        showModalLogo(data) {
            this.$refs['my-modal-view'].show()
            this.data_modal = data
        },

        hideModalLogo() {
            this.$refs['my-modal-view'].hide()
        },

        showModalEdit(data) {
            // console.log(data)
            this.$refs['my-modal-edit'].show()
            this.form_edit.name = data.name
            this.form_edit.address = data.address
            this.form_edit.company_name = data.company_name
            this.form_edit.account_id = data.account_id
            // this.form_edit.logo = data.logo
            // this.form_edit.is_web_reg = data.is_web_reg
            // this.form_edit.futures_type = data.futures_type._id
            this.id_ = data._id
        },

        hideModalEdit() {
            this.$refs['my-modal-edit'].hide()
        },

        editLogo(data) {
            this.form_edit.logo = data
        },

        async edit() {
            // let fd = new FormData()
            
            // fd.append('name', this.form_edit.name)
            // fd.append('address', this.form_edit.address)
            // fd.append('logo', this.form_edit.logo)
            // fd.append('is_web_reg', this.form_edit.is_web_reg)
            // fd.append('futures_type', this.form_edit.futures_type)

            try {
                const hit = await axios.put('v1/admin/broker/'+this.id_, this.form_edit)
                console.log(hit)

                if(!hit.data.iserror) {
                    window.location.href = '/broker' 
                }
            } 
            catch(e) {

            }
        },

        showModalAdd() {
            this.$refs['my-modal-add'].show()
        },

        hideModalAdd() {
            this.$refs['my-modal-add'].hide()
        },

        addLogo(data) {
            console.log(data)
            this.form.logo = data
        },

        async add() {
            
            // let fd = new FormData()
            
            // fd.append('name', this.form.name)
            // fd.append('address', this.form.address)
            // fd.append('futures_type', this.form.futures_type)
            // fd.append('is_web_reg', this.form.is_web_reg)
            // fd.append('logo', this.form.logo)

            // if(this.form.feature) {
            //     for(let i = 0; i < this.form.feature.length; i++) {
            //     let type = this.form.feature[i]

            //     fd.append('feature[' + [i] + ']', type)
            //     }
            // }
                console.log(this.form)
            try {
                const hit = await axios.post('/v1/admin/broker', this.form)
                console.log(hit)

                if(!hit.data.iserror) {
                    window.location.href = '/broker' 
                }
            } 
            catch(e) {

            }
        }


    },

    async mounted() {

// data broker 
        try {
            const res = await axios.get('/v1/admin/broker')
            this.data = res.data.data.broker
            console.log(res)
        } 
        catch(e) {
            console.log(e)
        }

// data futures 
        try {
            const res = await axios.get('/v1/admin/futures_trading')
            this.data_futures = res.data.data.futures_trading
            console.log(res)
        } 
        catch(e) {
            console.log(e)
        }
    }
}
</script>

<style scoped>

tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}


.list-data-modal {
    margin-left: 17px;
    font-size: 14px;
    font-weight: 600;
    list-style: inherit;
    padding-left: 5px;
}

.view-logo {
    /* background: rgba(0, 155, 60, 0.1); */
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #009B3C;
    cursor: pointer;
    padding: 10px;
}

.btn-edit {
    background: #FF6F00;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.title-menu {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* line-height: 49px; */
    color: #292b3f;
    margin-bottom: 45px;
}

.wrap-filter {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrap-table {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 50px;
}

.t-pcg {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #292b3f;
    display: inline-block;
    margin-right: 10px;
}

.slc-pcg {
  padding: 5px 10px;
  width: 172px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px #f4f3f9;
  background-color: #f4f3f9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #292b3f;
  font-size: 13px;
  margin-right: 10px;
}

</style>