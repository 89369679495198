<template>
    <div>
        <div class="title-menu"> User </div>

        <div class="wrap-filter">
            <div class="btn-by-email" @click="showModalAdd()"> Hapus Berdasarkan Email </div>
        </div>

        <div class="wrap-table">
            <table class="table table-striped table-hover table-borderless">
                <thead >
                    <tr style="height: 23px">
                        <th scope="col" class="text-center">No</th>
                        <th scope="col" class="text-left" style="width:15%">Nama</th>
                        <th scope="col" class="text-center">Email</th>
                        <th scope="col" class="text-left">No Telepon</th>
                        <th scope="col" class="text-left">Username</th>
                        <th class="text-center" style="width:15%">Aksi</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data_res,index) in data" :key="index">
                        <td class="text-center">{{offset + (index+1)}}</td>
                        <td scope="row" class="text-left">{{data_res.name}} </td>
            
                        <td scope="row" class="text-left">{{data_res.email}} </td>
                        <td scope="row" class="text-left">{{data_res.phone_number}} </td>
                        <td scope="row" class="text-left">{{data_res.username}} </td>

                        <td class="text-center">
                            <div class="text-center btn-delete" @click="showModalDelete(data_res)"> Hapus </div>
                        </td>
                    
                    </tr>

                </tbody>
            </table>

            <div class="flex-right mt-5">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    @click.native="changePage"
                ></b-pagination>
            </div>
        </div>

        <b-modal ref="my-modal-delete" hide-footer hide-header title="delete">
            <div class="wrap-modal-view">
                <div class="title-modal mb-5"> Hapus User</div>
                
                <form @submit.prevent="deleteUser">

                    <label for="" class="label-confirm"> Apakah Anda Yakin Ingin Menghapus User <span class="name-user"> {{form_edit.name}} </span> ?</label>                    
                    
                    <div class="text-right mt-5">
                        <div class="btn-cancel-modal mr-3" @click="hideModalDelete"> Tutup</div>
                        <button class="btn-delete-modal" type="submit"> Hapus </button>
                    </div>
                
                </form>
            </div>
        </b-modal>

        <b-modal ref="my-modal-deletebyemail" hide-footer hide-header title="delete-by-email">
            <div class="wrap-modal-view">
                <div class="title-modal mb-3"> Hapus Berdasarkan Email</div>
                
                <form enctype="multipart/form-data" @submit.prevent="deleteByEmail">
                    <label for="email" class="label-modal"> Masukan Email</label>
                    <input type="text" v-model="delete_email.email" id="email" class="form-modal mb-3">

                    <div v-if="error_code == 404" class="msg-error mt-2 mb-5"> User dengan E-mail <span class="bold">{{delete_email.email}} </span> Tidak Ada !!</div>
                    
                    <div class="text-right mt-3">
                        <div class="btn-cancel-modal mr-3" @click="hideModalAdd"> Tutup</div>
                        <button class="btn-delete-modal" type="submit"> Hapus </button>
                    </div>
                
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'User',
    data() {
        return {
            currentPage: 1,
            perPage:10,
            rows:0,
            offset:0,
            error_code:'',

            data:'',
            data_modal:'',
            id_:'',
            form_edit: {
                name:'',
                address:'',
                logo:''
            },
            form: {
                name:'',
                address:'',
                logo:''
            },
            delete_email: {
                email:''
            },

            base_img: process.env.VUE_APP_URL_IMG,
        }
    },

    methods: {

        async changePage() {

            if(this.currentPage == 1) {
                this.offset = 0
            } else {
                this.offset = (this.currentPage -1) * this.perPage
            }
            // console.log(this.offset, this.currentPage)
            const res = await axios.get('/v1/admin/user?limit='+this.perPage+'&offset='+this.offset)
            this.data = res.data.data
            // console.log(res)
        },

        showModalDelete(data) {
            console.log(data)
            this.$refs['my-modal-delete'].show()
            this.form_edit.name = data.name
            this.id_ = data._id
        },

        hideModalDelete() {
            this.$refs['my-modal-delete'].hide()
        },

        async deleteUser() {

            console.log(this.id_)

            try {
                const hit = await axios.delete('v1/admin/user/'+this.id_)
                console.log(hit)

                if(!hit.iserror) {
                    window.location.href = '/user' 
                }
            } 
            catch(e) {

            }
        },

        showModalAdd() {
            this.error_code = false
            this.$refs['my-modal-deletebyemail'].show()
        },

        hideModalAdd() {
            this.$refs['my-modal-deletebyemail'].hide()
        },

        async deleteByEmail() {
            this.error_code = false;

            try {
                const hit = await axios.put('/v1/admin/user/delete', this.delete_email)
                console.log(hit)

                if(!hit.iserror) {
                    window.location.href = '/user' 
                }
            } 
            catch(e) {
                let error = e.response
                console.log(error)
                this.error_code = error.data.status
                // console.log(e.response)
            }
        }
    },

    async mounted() {
        const res = await axios.get('/v1/admin/user?limit='+ this.perPage+'&offset=0')
        this.data = res.data.data
        console.log(res)
        this.rows = res.data.total

    }
}
</script>

<style scoped>

.msg-error{
    color: red;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    background: rgba(213, 0, 0, 0.1);
    border-radius: 10px;
    padding: 8px;
    
}

.label-confirm {
    font-weight: normal;
    font-size: 16px;
    font-style: normal;
    color: #000000;
}

.name-user {
    font-weight: 500;
    color: #000000;
    text-transform: uppercase;
}

.btn-by-email{
    /* width: 128px; */
    padding: 10px;
    /* height: 36px; */
    /* line-height: 36px; */
    border-radius: 5px;
    background-color: #FF6F00;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
    display: inline-block;
}

.btn-delete-modal {
    width: 128px;
    height: 36px;
    border-radius: 5px;
    background-color: #D50000;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}

.view-logo {
    /* background: rgba(0, 155, 60, 0.1); */
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #009B3C;
    cursor: pointer;
    padding: 10px;
}

.btn-delete {
    background: #D50000;
    border-radius: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.title-menu {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* line-height: 49px; */
    color: #292b3f;
    margin-bottom: 45px;
}

.wrap-filter {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wrap-table {
    border-radius: 5px;
    padding: 15px 25px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 50px;
}

.t-pcg {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #292b3f;
    display: inline-block;
    margin-right: 10px;
}

.slc-pcg {
  padding: 5px 10px;
  width: 172px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px #f4f3f9;
  background-color: #f4f3f9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #292b3f;
  font-size: 13px;
  margin-right: 10px;
}

</style>