import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import FutureTrading from "../views/FutureTrading.vue";
import Features from "../views/Features.vue";
import Banks from "../views/Banks.vue";
import Utility from "../views/Utility.vue";
import Broker from "../views/Broker.vue";
import Platform from "../views/Platform.vue";
import User from "../views/User.vue";
import Dashboard from "../components/Dashboard.vue";
import Login from "../views/Login.vue";
import Sidebar from "../components/Operator/Sidebar.vue";
import Transaction from "../views/Operator/Transaction.vue";
import Fund from "../views/Operator/Fund.vue";
import Gender from "../views/Operator/Gender.vue";
import Identity from "../views/Operator/Identity.vue";
import Industrial from "../views/Operator/Industrial.vue";
import Marriage from "../views/Operator/Marriage.vue";
import PurposeFund from "../views/Operator/PurposeFund.vue";
import PurposeOpenAccount from "../views/Operator/PurposeOpenAccount.vue";
import Religion from "../views/Operator/Religion.vue";
import Salary from "../views/Operator/Salary.vue";
import Position from "../views/Operator/Position.vue";
import Occupation from "../views/Operator/Occupation.vue";
import Province from "../views/Operator/Province.vue";
import Regency from "../views/Operator/Regency.vue";
import District from "../views/Operator/District.vue";
import Village from "../views/Operator/Village.vue";
import Education from "../views/Operator/Education.vue";
import CompanySector from "../views/Operator/CompanySector.vue";
import SidebarMenu from "../components/Monitoring/SidebarMenu.vue";
import Service from "../views/Monitoring/Service.vue";


Vue.use(VueRouter);

const authenticate = (to, from, next) => {

  const token = sessionStorage.getItem('token');
  const role = sessionStorage.getItem('role');

  if (token) {
      if (role == 1 ) {
          next()
          return
      } else {
          next('/')
      }
  } else {
      next('/login')
  }
}

const authenticate_operator = (to, from, next) => {

  const token = sessionStorage.getItem('token');
  const role = sessionStorage.getItem('role');

  if (token) {
      if (role == 2 ) {
          next()
          return
      } else {
          next('/operator')
      }
  } else {
      next('/login')
  }
}

const authenticate_monitoring = (to, from, next) => {

  const token = sessionStorage.getItem('token');
  const role = sessionStorage.getItem('role');

  if (token) {
      if (role == 18 ) {
          next()
          return
      } else {
          next('/operator')
      }
  } else {
      next('/login')
  }
}

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: authenticate,
    children:[
      {
        path: "/",
        name: "FutureTrading",
        component: FutureTrading,
      },
      {
        path: "/features",
        name: "Features",
        component: Features,
      },
      {
        path: "/banks",
        name: "Banks",
        component: Banks,
      },
      {
        path: "/utility",
        name: "Utility",
        component: Utility,
      },
      {
        path: "/broker",
        name: "Broker",
        component: Broker,
      },
      {
        path: "/platform",
        name: "Platform",
        component: Platform,
      },
      {
        path: "/user",
        name: "User",
        component: User,
      },
    ]
  },

  {
    path: "/operator",
    name: "Sidebar",
    component: Sidebar,
    beforeEnter: authenticate_operator,
    children:[
      {
        path: "/",
        name: "Transaction",
        component: Transaction,
      },
      {
        path: "/operator/fund",
        name: "Fund",
        component: Fund,
      },
      {
        path: "/operator/gender",
        name: "Gender",
        component: Gender,
      },
      {
        path: "/operator/identity",
        name: "Identity",
        component: Identity,
      },
      {
        path: "/operator/industrial",
        name: "Industrial",
        component: Industrial,
      },
      {
        path: "/operator/marriage",
        name: "Marriage",
        component: Marriage,
      },
      {
        path: "/operator/purpose_fund",
        name: "PurposeFund",
        component: PurposeFund,
      },
      {
        path: "/operator/purpose_open_account",
        name: "PurposeOpenAccount",
        component: PurposeOpenAccount,
      },
      {
        path: "/operator/religion",
        name: "Religion",
        component: Religion,
      },
      {
        path: "/operator/salary",
        name: "Salary",
        component: Salary,
      },
      {
        path: "/operator/occupation",
        name: "Occupation",
        component: Occupation,
      },
      {
        path: "/operator/position",
        name: "Position",
        component: Position,
      },
      {
        path: "/operator/province",
        name: "Province",
        component: Province,
      },
      {
        path: "/operator/regency",
        name: "Regency",
        component: Regency,
      },
      {
        path: "/operator/district",
        name: "District",
        component: District,
      },
      {
        path: "/operator/village",
        name: "Village",
        component: Village,
      },
      {
        path: "/operator/education",
        name: "Education",
        component: Education,
      },
      {
        path: "/operator/company_sector",
        name: "CompanySector",
        component: CompanySector,
      },
    ]
  },
  {
    path: "/monitoring",
    name: "SidebarMenu",
    component: SidebarMenu,
    beforeEnter: authenticate_monitoring,
    children:[
      {
        path: "/",
        name: "Service",
        component: Service,
      },
    ]
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

export default router;
