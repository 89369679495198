import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false;

// dev 
// axios.defaults.baseURL = 'https://api2-dev.abicommodity.co.id'

// v2 
// axios.defaults.baseURL = 'https://api-dev.bullionecosystem.com'

// production 
axios.defaults.baseURL = 'https://api.bullionecosystem.com'

// prod 
// axios.defaults.baseURL = 'https://api1.abicommodity.co.id'

// headers token
const token = sessionStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
